import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'
import FadeLoader from 'react-spinners/FadeLoader'
import CategoryForm from '../../../components/forms/CategoryForm'
import { getCategories } from '../../../functions/category'
import Select from 'react-select'
import {
  getSubCategory,
  updateSubCategory,
} from '../../../functions/subcategory'
import { useStateContext } from '../../../contexts/ContextProvider'
import AdminSidebar from '../../../components/sidebar/AdminSidebar'
import Navbar from '../../../components/nav/Navbar'
import HeaderProfile from '../../../components/HeaderProfile'
const AdminSubCategoryEdit = () => {
  const user = useSelector((state) => state.user)
  const { slug } = useParams()
  const navigate = useNavigate()
  const [name, setName] = useState('')
  const [loading, setLoading] = useState(false)
  const [categories, setCategories] = useState([])
  const [parent, setParent] = useState('')
  const { activeMenu } = useStateContext()

  const loadCategories = () => {
    getCategories()
      .then((res) => {
        if (res.status === 'ok') {
          setCategories(res?.data)
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message)
      })
  }

  const loadSubCategories = () => {
    getSubCategory(slug)
      .then((res) => {
        if (res.status === 'ok') {
          setName(res.data.subcategories.name)
          setParent(res.data.subcategories.parent?._id)
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message)
      })
  }

  useEffect(() => {
    loadCategories()
    loadSubCategories()
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault()

    if (!parent.length) {
      return toast.error('Please select category')
    }

    setLoading(true)
    let data = {
      name,
      parent,
    }

    updateSubCategory(slug, data, user.token)
      .then((res) => {
        if (res.status === 'ok') {
          setLoading(false)
          toast.success(`"${name}" is updated`)
          setName('')
          loadSubCategories()
          navigate('/admin/sub-category')
        } else {
          setLoading(false)
          toast.error(res)
        }
      })
      .catch((err) => {
        setLoading(false)
        toast.error(err.response.data.message)
      })
  }

  return (
    <div className="flex relative">
      {activeMenu ? (
        <div className="w-72 sidebar dark:bg-secondary-dark-bg bg-white ">
          <AdminSidebar />
        </div>
      ) : (
        <div className="w-0 dark:bg-secondary-dark-bg">
          <AdminSidebar />
        </div>
      )}
      <div
        className={
          activeMenu
            ? 'dark:bg-main-dark-bg  bg-main-bg min-h-screen w-full  '
            : 'bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 '
        }
      >
        <div className="md:static bg-main-bg dark:bg-main-dark-bg navbar w-full">
          <Navbar />
        </div>
        <div className="m-2 md:m-10 mt-10 p-2 md:p-5 bg-white rounded-1xl">
          <HeaderProfile title="Edit Sub Category" />
          <div className="w-96">
            {loading && (
              <div className="flex justify-start items-center">
                <FadeLoader color="#06b6d4" loading={loading} size={20} />
              </div>
            )}
            <div className="md:col-span-5 mr-5">
              <label>Parent category</label>
              <Select
                options={categories}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option._id}
                onChange={(e) => setParent(e?._id)}
                value={categories.filter((id) => {
                  return id._id === parent
                })}
                key={categories._id}
                className="block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
              />
            </div>
            <br />

            <CategoryForm
              handleSubmit={handleSubmit}
              name={name}
              setName={setName}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminSubCategoryEdit
