import React, { useState, useEffect } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
  followUser,
  getCurrentPostsProfile,
  getCurrentProfile,
  getUserFollowers,
  getUserFollowing,
  unFollowUser,
} from '../../../functions/users'
import { CheckCircleFilled, UserAddOutlined } from '@ant-design/icons'
import FadeLoader from 'react-spinners/FadeLoader'
import parse from 'html-react-parser'
import { smartTrim } from '../../../utils/helpers'
import moment from 'moment'

const MainProfile = () => {
  const { username } = useParams()
  const user = useSelector((state) => state.user)
  const navigation = useNavigate()

  const [userProfile, setUserProfile] = useState([])
  const [userPosts, setUserPosts] = useState([])
  const [openTab, setOpenTab] = useState(1)
  const [loading, setLoading] = useState(false)

  const [followers, setFollowers] = useState([])
  const [loggedUserFollowStats, setLoggedUserFollowStats] = useState({})

  const [followings, setFollowings] = useState([])

  const loadUser = () => {
    getCurrentProfile(user?.token, username)
      .then((res) => {
        if (res.status === 'ok') {
          setUserProfile(res.data)
          getUserFollowers(user?.token, res?.data?.user?._id)
            .then((resData) => {
              if (resData.status === 'ok') {
                setFollowers(resData.data)
                setLoggedUserFollowStats(resData.data.userFollowStats)
              }
            })
            .catch((err) => {
              console.log(err.response.data.message)
            })

          getUserFollowing(user?.token, res?.data?.user?._id)
            .then((resDataFling) => {
              if (resDataFling.status === 'ok') {
                setFollowings(resDataFling.data)
              }
            })
            .catch((err) => {
              console.log(err.response.data.message)
            })
        }

        if (res === 'No user found') {
          navigation('*')
        }
      })
      .catch((err) => {
        console.log(err.response.data.message)
      })
  }

  const loadPosts = () => {
    getCurrentPostsProfile(user?.token, username)
      .then((res) => {
        if (res.status === 'ok') {
          setUserPosts(res.data)
        }
      })
      .catch((err) => {
        console.log(err.response.data.message)
      })
  }

  useEffect(() => {
    loadUser()
    loadPosts()

    // eslint-disable-next-line
  }, [])

  const isFollowing =
    loggedUserFollowStats?.following?.length > 0 &&
    loggedUserFollowStats?.following.filter(
      (following) => following?.user === userProfile?.user?._id
    ).length > 0

  const handleFollow = async (token, userToFollowId) => {
    setLoading(true)
    await followUser(token, userToFollowId, setLoggedUserFollowStats)
    loadUser()
    setLoading(false)
  }
  const handleUnFollow = async (token, userToUnFollowId) => {
    setLoading(true)
    await unFollowUser(token, userToUnFollowId, setLoggedUserFollowStats)
    loadUser()
    setLoading(false)
  }

  const handleFollowList = async (token, userToFollowId) => {
    await followUser(token, userToFollowId, setLoggedUserFollowStats)
    loadUser()
  }
  const handleUnFollowList = async (token, userToUnFollowId) => {
    await unFollowUser(token, userToUnFollowId, setLoggedUserFollowStats)
    loadUser()
  }

  //   useEffect(() => {
  //     if (!userProfile) {
  //       navigation('*')
  //     }
  //     // eslint-disable-next-line
  //   }, [userProfile])

  return (
    <main className="profile-page">
      <section className="h-[30rem] w-full rounded-t bg-[url('https://unsplash.com/photos/4eqEe8PnQn8/download?ixid=MnwxMjA3fDB8MXx0b3BpY3x8NnNNVmpUTFNrZVF8fHx8fDJ8fDE2NzM3NTQ0NzI&force=true&w=1920')] bg-cover bg-center"></section>
      <section className="bg-blueGray-200">
        <div className="container mx-auto sm:px-4 lg:px-16">
          <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64">
            <div className="px-6 py-6">
              <div className="flex flex-wrap justify-center">
                <div className="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center">
                  <img
                    src={userProfile?.user?.profilePic}
                    alt={userProfile?.user?.firstName}
                    className="rounded-full"
                    style={{
                      width: '15rem',
                      height: '15rem',
                      objectFit: 'cover',
                    }}
                  />
                </div>
                <div className="w-full lg:w-4/12 lg:order-3">
                  <div className="flex justify-center">
                    <div className="py-6 px-3 sm:mt-0">
                      <span className="text-xl font-bold block uppercase tracking-wide text-blueGray-600">
                        <p className="d-block w-full h-5 "> </p>
                      </span>
                      {user?.id === userProfile?.user?._id ? (
                        <NavLink
                          to={'/user/dashboard'}
                          className="bg-cyan-500 active:bg-cyan-600 uppercase text-white hover:text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                        >
                          Settings
                        </NavLink>
                      ) : (
                        <>
                          {loading && (
                            <div className="flex justify-start items-center">
                              <FadeLoader color="#06b6d4" loading={loading} />
                            </div>
                          )}
                          {!loading && (
                            <button
                              className="bg-cyan-500 active:bg-cyan-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                              type="button"
                              onClick={() =>
                                !isFollowing
                                  ? handleFollow(
                                      user?.token,
                                      userProfile?.user?._id
                                    )
                                  : handleUnFollow(
                                      user?.token,
                                      userProfile?.user?._id
                                    )
                              }
                            >
                              {isFollowing ? (
                                <>
                                  <CheckCircleFilled
                                    style={{
                                      color: 'white',
                                      fontSize: 14,
                                      position: 'relative',
                                      top: '-2px',
                                      right: '2px',
                                    }}
                                  />{' '}
                                  Following
                                </>
                              ) : (
                                <>
                                  <UserAddOutlined
                                    style={{
                                      color: 'white',
                                      fontSize: 14,
                                      position: 'relative',
                                      top: '-2px',
                                      right: '2px',
                                    }}
                                  />{' '}
                                  Follow
                                </>
                              )}
                            </button>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-4/12 px-4 lg:order-1">
                  <div className="flex justify-center py-4 lg:pt-4 pt-8">
                    <div className="mr-4 p-3 text-center">
                      <span className="text-xl font-bold block uppercase tracking-wide text-blueGray-600">
                        {userProfile?.followersLength}
                      </span>
                      <span className="text-sm text-blueGray-400">
                        Followers
                      </span>
                    </div>
                    <div className="mr-4 p-3 text-center">
                      <span className="text-xl font-bold block uppercase tracking-wide text-blueGray-600">
                        {userProfile?.followingLength}
                      </span>
                      <span className="text-sm text-blueGray-400">
                        Following
                      </span>
                    </div>
                    <div className="lg:mr-4 p-3 text-center">
                      <span className="text-xl font-bold block uppercase tracking-wide text-blueGray-600">
                        {userPosts?.length}
                      </span>
                      <span className="text-sm text-blueGray-400">Posts</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-center mt-12">
                <h3 className="text-4xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">
                  {userProfile?.user?.firstName} {userProfile?.user?.lastName}
                </h3>
                <div className="text-lg leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase">
                  Author
                </div>
              </div>
              {/* <div className="mt-10 py-10 border-t border-blueGray-200 text-center">
                <div className="flex flex-wrap justify-center">
                  <div className="w-full lg:w-9/12 px-4">
                    <p className="mb-4 text-lg leading-relaxed text-blueGray-700">
                      An artist of considerable range, Jenna the name taken by
                      Melbourne-raised, Brooklyn-based Nick Murphy writes,
                      performs and records all of his own music, giving it a
                      warm, intimate feel with a solid groove structure. An
                      artist of considerable range.
                    </p>
                  </div>
                </div>
              </div> */}

              <div className="mt-10 py-6 text-center">
                <div className="flex flex-wrap justify-center">
                  <div className="w-full lg:w-9/12 px-4">
                    <ul className="flex text-sm font-medium justify-center">
                      <li>
                        <div
                          onClick={() => setOpenTab(1)}
                          className={`-mb-px lg:mx-20 border-b-2 border-current pb-2 mr-3 ${
                            openTab === 1 ? 'text-cyan-500' : 'text-gray-500'
                          }  cursor-pointer`}
                        >
                          Followers
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => setOpenTab(2)}
                          className={`-mb-px lg:mx-20 border-b-2 border-current pb-2 mr-3 ${
                            openTab === 2 ? 'text-cyan-500' : 'text-gray-500'
                          }  cursor-pointer`}
                        >
                          Following
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => setOpenTab(3)}
                          className={`-mb-px lg:mx-20 border-b-2 border-current pb-2 mr-3 ${
                            openTab === 3 ? 'text-cyan-500' : 'text-gray-500'
                          }  cursor-pointer`}
                        >
                          Posts
                        </div>
                      </li>
                    </ul>

                    <div className="pb-3 mt-6 bg-white">
                      <div className={openTab === 1 ? 'block' : 'hidden'}>
                        {followers?.followers?.length === 0 && (
                          <div className="flex justify-center align-middle px-2 my-3 py-2 bg-red-100 rounded-md">
                            <p className="text-center py-2">
                              User does not have any followers yet!
                            </p>
                          </div>
                        )}
                        {followers?.followers?.length > 0 &&
                          followers?.followers?.map((pf) => {
                            const isFollowingList =
                              loggedUserFollowStats?.following?.length > 0 &&
                              loggedUserFollowStats?.following?.some(
                                (ele) => ele.user === pf.user._id
                              )

                            return (
                              <div
                                className="flex justify-between align-middle px-2 my-3 py-2 bg-slate-100 rounded-md"
                                key={pf?.user?._id}
                              >
                                <p className="text-left py-2">
                                  {pf?.user?.firstName +
                                    ' ' +
                                    pf?.user?.lastName}{' '}
                                </p>
                                <div>
                                  {pf?.user?._id !== user?.id && (
                                    <>
                                      <button
                                        className="bg-cyan-500 active:bg-cyan-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() =>
                                          !isFollowingList
                                            ? handleFollowList(
                                                user?.token,
                                                pf?.user?._id
                                              )
                                            : handleUnFollowList(
                                                user?.token,
                                                pf?.user?._id
                                              )
                                        }
                                      >
                                        {isFollowingList ? (
                                          <>
                                            <CheckCircleFilled
                                              style={{
                                                color: 'white',
                                                fontSize: 14,
                                                position: 'relative',
                                                top: '-2px',
                                                right: '2px',
                                              }}
                                            />{' '}
                                          </>
                                        ) : (
                                          <>
                                            <UserAddOutlined
                                              style={{
                                                color: 'white',
                                                fontSize: 14,
                                                position: 'relative',
                                                top: '-2px',
                                                right: '2px',
                                              }}
                                            />{' '}
                                          </>
                                        )}
                                      </button>
                                    </>
                                  )}
                                </div>
                              </div>
                            )
                          })}
                      </div>
                      <div className={openTab === 2 ? 'block' : 'hidden'}>
                        {followings?.length === 0 && (
                          <div className="flex justify-center align-middle px-2 my-3 py-2 bg-red-100 rounded-md">
                            <p className="text-center py-2">
                              User does not have any followings yet!
                            </p>
                          </div>
                        )}
                        {followings?.length > 0 &&
                          followings?.map((pf) => {
                            const isFollowingList =
                              loggedUserFollowStats?.following?.length > 0 &&
                              loggedUserFollowStats?.following?.some(
                                (ele) => ele.user === pf.user._id
                              )

                            return (
                              <div
                                className="flex justify-between align-middle px-2 my-3 py-2 bg-slate-100 rounded-md"
                                key={pf?.user?._id}
                              >
                                <p className="text-left py-2">
                                  {pf?.user?.firstName +
                                    ' ' +
                                    pf?.user?.lastName}{' '}
                                </p>
                                <div>
                                  {pf?.user?._id !== user?.id && (
                                    <>
                                      <button
                                        className="bg-cyan-500 active:bg-cyan-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() =>
                                          !isFollowingList
                                            ? handleFollowList(
                                                user?.token,
                                                pf?.user?._id
                                              )
                                            : handleUnFollowList(
                                                user?.token,
                                                pf?.user?._id
                                              )
                                        }
                                      >
                                        {isFollowingList ? (
                                          <>
                                            <CheckCircleFilled
                                              style={{
                                                color: 'white',
                                                fontSize: 14,
                                                position: 'relative',
                                                top: '-2px',
                                                right: '2px',
                                              }}
                                            />{' '}
                                          </>
                                        ) : (
                                          <>
                                            <UserAddOutlined
                                              style={{
                                                color: 'white',
                                                fontSize: 14,
                                                position: 'relative',
                                                top: '-2px',
                                                right: '2px',
                                              }}
                                            />{' '}
                                          </>
                                        )}
                                      </button>
                                    </>
                                  )}
                                </div>
                              </div>
                            )
                          })}
                      </div>
                      <div className={openTab === 3 ? 'block' : 'hidden'}>
                        {userPosts?.length === 0 && (
                          <div className="flex justify-center align-middle px-2 my-3 py-2 bg-red-100 rounded-md">
                            <p className="text-center py-2">
                              User does not have any post yet!
                            </p>
                          </div>
                        )}

                        {userPosts && (
                          <section className="w-full md:w-3/3 flex flex-col items-center">
                            <div className="grid grid-flow-row xs:grid-cols-12 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-6 gap-6">
                              {userPosts?.map((p, i) => {
                                const postExcerpt = smartTrim(
                                  p?.description.replace(
                                    /<img[^>"']*((("[^"]*")|('[^']*'))[^"'>]*)*>/g,
                                    ''
                                  ),
                                  100,
                                  ' ',
                                  ' ...'
                                )
                                return (
                                  <div className="col-span-3" key={i}>
                                    <article className="flex flex-col shadow my-4">
                                      <NavLink
                                        to={`/post/${p?.slug}`}
                                        className="hover:opacity-75"
                                      >
                                        <img
                                          src={p?.images[0].url}
                                          alt={p?.title}
                                          className="w-full max-h-[35rem] h-[20rem]"
                                        />
                                      </NavLink>
                                      <div className="bg-white flex flex-col justify-start p-6">
                                        <NavLink
                                          to={`/category/${p?.category.slug}`}
                                          className="text-cyan-600 text-sm font-bold uppercase pb-4"
                                        >
                                          {p?.category.name}
                                        </NavLink>
                                        <NavLink
                                          to={`/post/${p?.slug}`}
                                          className="text-lg text-left hover:text-gray-700 pb-4"
                                        >
                                          {p?.title}
                                        </NavLink>
                                        <p className="text-sm pb-3 flex flex-row">
                                          <NavLink
                                            to={`/${p?.postedBy?.username}`}
                                          >
                                            <img
                                              src={p?.postedBy?.profilePic}
                                              alt="Profile Pic"
                                              className="rounded-full w-6 h-6 mr-2"
                                            />
                                          </NavLink>
                                          <NavLink
                                            to={`/${p?.postedBy?.username}`}
                                            className="font-semibold hover:text-gray-800"
                                          >
                                            {p?.postedBy.firstName}
                                          </NavLink>
                                          , Published on:
                                          {moment(p?.createdAt).fromNow()}
                                        </p>
                                        <NavLink
                                          to={`/post/${p?.slug}`}
                                          className="pb-6 text-left"
                                        >
                                          {parse(`
                ${postExcerpt}
              `)}
                                        </NavLink>
                                        <NavLink
                                          to={`/post/${p?.slug}`}
                                          className="md:w-3/5 sm:w-full text-center uppercase bg-cyan-600 text-white font-bold text-sm rounded hover:bg-cyan-800 hover:text-white px-5 py-3 mt-4"
                                        >
                                          Continue Reading
                                        </NavLink>
                                      </div>
                                    </article>
                                  </div>
                                )
                              })}
                            </div>
                          </section>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}

export default MainProfile
