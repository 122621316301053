import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import FadeLoader from 'react-spinners/FadeLoader';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useStateContext } from '../../../contexts/ContextProvider';
import { getPost, updateUserPost } from '../../../functions/post';
import { getCategories, getCategorySubs } from '../../../functions/category';
import UserPostEditForm from '../../../components/forms/UserPostEditForm';
import Navbar from '../../../components/nav/Navbar';
import HeaderProfile from '../../../components/HeaderProfile';
import UserSidebar from '../../../components/sidebar/UserSidebar';
import EditImageUpload from '../../../components/forms/EditImageUpload';

const initialState = {
  title: '',
  description: '',
  category: '',
  subcategories: [],
  images: [],
  tags: [],
};
const UserPostEdit = () => {
  const { slug } = useParams();
  const [values, setValues] = useState(initialState);
  const [categories, setCategories] = useState([]);
  const [subOptions, setSubOptions] = useState([]);
  const [arrayOfSubs, setArrayOfSubs] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const { activeMenu } = useStateContext();

  useEffect(() => {
    loadPosts();
    loadCategories();
  }, []);

  const loadPosts = () => {
    getPost(slug).then((p) => {
      setValues({ ...values, ...p.data });
      getCategorySubs(p?.data?.category?._id).then((res) => {
        setSubOptions(res?.data);
      });
      let arr = [];

      p.data.subcategories.map((s) => {
        arr.push(s?._id);
      });

      setArrayOfSubs((prev) => arr);
    });
  };

  const loadCategories = () => {
    getCategories()
      .then((res) => {
        if (res.status === 'ok') {
          setCategories(res?.data);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleCategoryChange = (e) => {
    e.preventDefault();
    setValues({ ...values, subs: [] });
    setSelectedCategory(e.target.value);
    getCategorySubs(e.target.value).then((res) => {
      setSubOptions(res?.data);
    });
    if (values.category?._id === e.target.value) {
      loadPosts();
    }
    setArrayOfSubs([]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!values.images.length) {
      return toast.error('Please upload feature image');
    }
    if (!values.title.length) {
      return toast.error('Title required');
    }
    if (values.title.length > 100) {
      return toast.error('Title should not be more than 100 characters');
    }
    if (!categories.length) {
      return toast.error('Category required');
    }
    if (!values.subcategories.length) {
      return toast.error('Sub Category required');
    }
    if (!values.description.length) {
      return toast.error('Description required');
    }

    setLoading(true);

    values.subcategories = arrayOfSubs;
    values.category = selectedCategory ? selectedCategory : values.category;

    const { title, description, category, subcategories, images, tags } =
      values;

    let data = {
      title,
      description,
      category,
      subcategories,
      images,
      tags,
    };

    updateUserPost(slug, data, user.token)
      .then((res) => {
        if (res.status === 'ok') {
          setLoading(false);
          setInterval(() => {
            navigate('/user/post');
            window.location.reload();
          }, 1500);
          toast.success(`"${res.data.title}" is updated`);
        } else {
          setLoading(false);
          toast.error(res);
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response.message);
      });
  };
  return (
    <div className="flex relative">
      {activeMenu ? (
        <div className="w-72 sidebar dark:bg-secondary-dark-bg bg-white ">
          <UserSidebar />
        </div>
      ) : (
        <div className="w-0 dark:bg-secondary-dark-bg">
          <UserSidebar />
        </div>
      )}
      <div
        className={
          activeMenu
            ? 'dark:bg-main-dark-bg  bg-main-bg min-h-screen w-full  '
            : 'bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 '
        }
      >
        <div className="md:static bg-main-bg dark:bg-main-dark-bg navbar w-full">
          <Navbar />
        </div>
        <div className="m-2 md:m-10 mt-10 p-2 md:p-5 bg-white rounded-1xl">
          <HeaderProfile title="Edit Post" />
          <div className="w-96">
            {loading && (
              <div className="flex justify-start items-center">
                <FadeLoader color="#06b6d4" loading={loading} size={20} />
              </div>
            )}
          </div>

          <div className="w-full">
            <div className="md:col-span-5">
              <label>Feature Image</label>
              <EditImageUpload
                values={values}
                setValues={setValues}
                setLoading={setLoading}
              />
            </div>

            <UserPostEditForm
              handleSubmit={handleSubmit}
              handleChange={handleChange}
              setValues={setValues}
              values={values}
              handleCategoryChange={handleCategoryChange}
              categories={categories}
              subOptions={subOptions}
              arrayOfSubs={arrayOfSubs}
              setArrayOfSubs={setArrayOfSubs}
              selectedCategory={selectedCategory}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserPostEdit;
