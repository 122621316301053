import React, { useState } from 'react'
import { toast } from 'react-toastify'
import FadeLoader from 'react-spinners/FadeLoader'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import axios from 'axios'
import { Tabs } from 'antd'
import { CheckCircleFilled, ExclamationCircleOutlined } from '@ant-design/icons'
import ImageUpload from '../../../components/forms/ImageUpload'
import {
  updateAdminGooglePassword,
  updateUserGooglePassword,
  updateUserInfo,
  updateUserPassword,
} from '../../../functions/users'
import ProfileImageUpload from '../../../components/forms/ProfileImageUpload'
import Cookies from 'js-cookie'
import jwt_decode from 'jwt-decode'
import moment from 'moment'
import { useStateContext } from '../../../contexts/ContextProvider'
import AdminSidebar from '../../../components/sidebar/AdminSidebar'
import Navbar from '../../../components/nav/Navbar'
import HeaderProfile from '../../../components/HeaderProfile'

const AdminSettings = () => {
  const user = useSelector((state) => state.user)
  const [openTab, setOpenTab] = useState(1)

  const initialState = {
    firstName: user?.firstName,
    lastName: user?.lastName,
    email: user?.email,
    password: '',
    cpassword: '',
    profilePic: user?.profilePic,
  }
  const [values, setValues] = useState(initialState)
  const [googlePass, setGooglePass] = useState({
    gPassword: '',
    conGPassword: '',
  })
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { activeMenu } = useStateContext()

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }

  const onChangeGoogle = (e) => {
    setGooglePass({ ...googlePass, [e.target.name]: e.target.value })
  }

  let decodedToken = jwt_decode(user?.token)
  const date1 = new Date(decodedToken.exp * 1000)
  const date2 = moment(new Date())

  const startDate = moment(date2, 'DD.MM.YYYY')
  const endDate = moment(date1, 'DD.MM.YYYY')

  const cookieDate = Number(endDate.diff(startDate, 'days'))

  const onSubmit = async (e) => {
    e.preventDefault()

    setError('')
    setSuccess('')
    const { firstName, lastName, email, password } = values

    if (firstName.length > 0 && lastName.length > 0) {
      let newdata = {
        firstName,
        lastName,
        profilePic: values.profilePic,
      }

      try {
        setLoading(true)
        updateUserInfo(newdata, user.token)
          .then((res) => {
            if (res.status === 'ok') {
              setError('')
              setSuccess(res.data.message)
              setTimeout(() => {
                setSuccess('')
              }, 2000)

              dispatch({
                type: 'LOGIN',
                payload: {
                  ...user,
                  firstName: res.data.data.firstName,
                  lastName: res.data.data.lastName,
                  profilePic: res.data.data.profilePic,
                },
              })

              Cookies.set(
                'user',
                JSON.stringify({
                  ...user,
                  firstName: res.data.data.firstName,
                  lastName: res.data.data.lastName,
                  profilePic: res.data.data.profilePic,
                }),
                { expires: cookieDate }
              )

              setLoading(false)
            } else {
              setLoading(false)
              setError(res)
            }
          })
          .catch((err) => {
            setLoading(false)
            toast.error(err.response.data.message)
          })
      } catch (error) {
        setLoading(false)
        setSuccess('')
        setError(error.response.data.message)
      }
    } else {
      setLoading(false)
    }
  }

  const onSubmitChangePassword = async (e) => {
    e.preventDefault()

    setError('')
    setSuccess('')
    const { cpassword, password } = values

    if (cpassword !== password) {
      setError('Password not matched')
      return toast.error('Password not matched!')
    }

    if (cpassword.length > 0 && password.length > 0) {
      let newdata = {
        cpassword,
        password,
      }

      try {
        setLoading(true)
        updateUserPassword(newdata, user.token)
          .then((res) => {
            if (res.status === 'ok') {
              setError('')
              setSuccess(res.data)
              setValues({ ...values, cpassword: '', password: '' })
              // setTimeout(() => {
              //   setSuccess('')
              // }, 2000)

              setLoading(false)
            } else {
              setLoading(false)
              setError(res)
            }
          })
          .catch((err) => {
            setLoading(false)
            toast.error(err.response.data.message)
          })
      } catch (error) {
        setLoading(false)
        setSuccess('')
        setError(error.response.data.message)
      }
    } else {
      setLoading(false)
    }
  }

  const googlePasswordChange = async (e) => {
    e.preventDefault()

    setError('')
    setSuccess('')
    const { gPassword, conGPassword } = googlePass

    if (gPassword !== conGPassword) {
      setError('Password not matched')
      return toast.error('Password not matched!')
    }

    if (gPassword.length > 0 && conGPassword.length > 0) {
      let newdata = {
        password: conGPassword,
      }

      try {
        setLoading(true)
        updateAdminGooglePassword(newdata, user.token)
          .then((res) => {
            if (res.status === 'ok') {
              setError('')
              setSuccess(res.data)
              setGooglePass({ ...googlePass, gPassword: '', conGPassword: '' })
              setTimeout(() => {
                dispatch({
                  type: 'LOGIN',
                  payload: {
                    ...user,
                    p: 'ok',
                  },
                })

                Cookies.set(
                  'user',
                  JSON.stringify({
                    ...user,
                    p: 'ok',
                  }),
                  { expires: 30 }
                )

                setSuccess('')
              }, 2000)

              setLoading(false)
            } else {
              setLoading(false)
              setError(res)
            }
          })
          .catch((err) => {
            setLoading(false)
            toast.error(err.response.data.message)
          })
      } catch (error) {
        setLoading(false)
        setSuccess('')
        setError(error.response.data.message)
      }
    } else {
      toast.error('Please type valid password.')
      setLoading(false)
    }
  }

  return (
    <div className="flex relative">
      {activeMenu ? (
        <div className="w-72 sidebar dark:bg-secondary-dark-bg bg-white ">
          <AdminSidebar />
        </div>
      ) : (
        <div className="w-0 dark:bg-secondary-dark-bg">
          <AdminSidebar />
        </div>
      )}
      <div
        className={
          activeMenu
            ? 'dark:bg-main-dark-bg  bg-main-bg min-h-screen w-full  '
            : 'bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 '
        }
      >
        <div className="md:static bg-main-bg dark:bg-main-dark-bg navbar w-full">
          <Navbar />
        </div>
        <div className="m-2 md:m-10 mt-10 p-2 md:p-5 bg-white rounded-1xl">
          <HeaderProfile title="Settings" />
          {loading && (
            <div className="flex justify-start items-center">
              <FadeLoader color="#06b6d4" loading={loading} size={20} />
            </div>
          )}
          <div className="w-full">
            {success && (
              <h5 className="text-left auth_error_success mt-3 text-success d-flex justify-content-center align-items-center">
                <CheckCircleFilled
                  style={{
                    fontSize: '22px',
                    color: 'green',
                    marginRight: '5px',
                    position: 'relative',
                    top: '-3px',
                  }}
                />
                {success}
              </h5>
            )}
            {error && (
              <h5 className="text-left auth_error_success my-3 text-danger d-flex justify-content-center align-items-center">
                {' '}
                <ExclamationCircleOutlined
                  style={{
                    fontSize: '20px',
                    color: 'red',
                    marginRight: '5px',
                    position: 'relative',
                    top: '-3px',
                  }}
                />{' '}
                {error}
              </h5>
            )}
          </div>

          <div className="flex flex-col items-start justify-start">
            <ul className="flex text-sm font-medium">
              <li>
                <div
                  onClick={() => setOpenTab(1)}
                  className={`-mb-px border-b-4 border-current pb-2 mr-3 ${
                    openTab === 1 ? 'text-cyan-500' : 'text-gray-500'
                  }  cursor-pointer`}
                >
                  General Information
                </div>
              </li>
              <li>
                <div
                  onClick={() => setOpenTab(2)}
                  className={`-mb-px border-b-4 border-current pb-2 mr-3 ${
                    openTab === 2 ? 'text-cyan-500' : 'text-gray-500'
                  }  cursor-pointer`}
                >
                  Password
                </div>
              </li>
            </ul>
            <div className="pb-3 mt-6 bg-white">
              <div className={openTab === 1 ? 'block' : 'hidden'}>
                <form onSubmit={onSubmit}>
                  <div className="md:col-span-5">
                    <label>Profile Image</label>
                    <ProfileImageUpload
                      values={values}
                      setValues={setValues}
                      setLoading={setLoading}
                    />
                  </div>

                  <div className="md:col-span-5">
                    <label>First Name</label>
                    <input
                      type="text"
                      placeholder="First name"
                      name="firstName"
                      className="block w-full px-4 py-2 mt-2 text-cyan-700 bg-white border rounded-md focus:border-cyan-700 focus:outline-none"
                      onChange={onChange}
                      value={values.firstName}
                      required
                    />
                  </div>

                  <div className="md:col-span-5">
                    <label>Last Name</label>
                    <input
                      type="text"
                      placeholder="Last name"
                      name="lastName"
                      className="block w-full px-4 py-2 mt-2 text-cyan-700 bg-white border rounded-md focus:border-cyan-700 focus:outline-none"
                      onChange={onChange}
                      value={values.lastName}
                      required
                    />
                  </div>

                  <div className="md:col-span-5">
                    <label>Email</label>
                    <input
                      className="block w-full px-4 py-2 mt-2 text-cyan-700 bg-white border rounded-md focus:border-cyan-700 focus:outline-none"
                      value={values.email}
                      disabled
                    />
                  </div>

                  <div className="d-grid gap-2 my-5">
                    <button
                      type="submit"
                      className={`w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform ${
                        loading
                          ? 'bg-gray-500 hover:bg-gray-500'
                          : 'bg-cyan-500 hover:bg-cyan-500'
                      }  rounded-md  focus:outline-none focus:bg-cyan-600`}
                      disabled={loading}
                    >
                      Update
                    </button>
                  </div>
                </form>
              </div>
              <div className={openTab === 2 ? 'block' : 'hidden'}>
                {user?.google === true && !user?.p ? (
                  <form onSubmit={googlePasswordChange}>
                    <div className="md:col-span-5 my-3">
                      <label>New password</label>
                      <input
                        type="password"
                        placeholder="New password"
                        name="gPassword"
                        className="block w-full px-4 py-2 mt-2 text-cyan-700 bg-white border rounded-md focus:border-cyan-700 focus:outline-none"
                        onChange={onChangeGoogle}
                        value={googlePass.gPassword}
                        required
                      />
                    </div>

                    <div className="md:col-span-5 my-3">
                      <label>Confirm new password</label>
                      <input
                        type="password"
                        placeholder="New password"
                        name="conGPassword"
                        className="block w-full px-4 py-2 mt-2 text-cyan-700 bg-white border rounded-md focus:border-cyan-700 focus:outline-none"
                        onChange={onChangeGoogle}
                        value={googlePass.conGPassword}
                        required
                      />
                    </div>

                    <div className="d-grid gap-2 my-5">
                      <button
                        type="submit"
                        className={`w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform ${
                          loading
                            ? 'bg-gray-500 hover:bg-gray-500'
                            : 'bg-cyan-500 hover:bg-cyan-500'
                        }  rounded-md  focus:outline-none focus:bg-cyan-600`}
                        disabled={loading}
                      >
                        Create password
                      </button>
                    </div>
                  </form>
                ) : (
                  <form onSubmit={onSubmitChangePassword}>
                    <div className="md:col-span-5">
                      <label>Current Password</label>
                      <input
                        type="password"
                        placeholder="Current password"
                        name="cpassword"
                        className="block w-full px-4 py-2 mt-2 text-cyan-700 bg-white border rounded-md focus:border-cyan-700 focus:outline-none"
                        onChange={onChange}
                        value={values.cpassword}
                        required
                      />
                    </div>

                    <div className="md:col-span-5 my-3">
                      <label>Password</label>
                      <input
                        type="password"
                        placeholder="New password"
                        name="password"
                        className="block w-full px-4 py-2 mt-2 text-cyan-700 bg-white border rounded-md focus:border-cyan-700 focus:outline-none"
                        onChange={onChange}
                        value={values.password}
                        required
                      />
                    </div>

                    <div className="d-grid gap-2 my-5">
                      <button
                        type="submit"
                        className={`w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform ${
                          loading
                            ? 'bg-gray-500 hover:bg-gray-500'
                            : 'bg-cyan-500 hover:bg-cyan-500'
                        }  rounded-md  focus:outline-none focus:bg-cyan-600`}
                        disabled={loading}
                      >
                        Update
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminSettings
