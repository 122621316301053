import React from 'react'
import { MdOutlineCancel } from 'react-icons/md'

import { userProfileData } from '../../data/dummy'
import { useStateContext } from '../../contexts/ContextProvider'
import avatar from '../../data/avatar.jpg'
import Button from './Button'

const UserProfile = ({ user }) => {
  return (
    <div className="nav-item absolute right-1 top-12 bg-white dark:bg-[#42464D] p-4 rounded-lg w-96">
      <div className="flex justify-between items-center">
        <p className="font-semibold text-lg dark:text-gray-200"></p>
        <Button
          icon={<MdOutlineCancel />}
          color="rgb(153, 171, 180)"
          size="2xl"
          borderRadius="50%"
        />
      </div>
      <div className="flex gap-2 items-center pb-6">
        <img
          className="rounded-full h-24 w-24 mr-4"
          src={user?.profilePic}
          alt="user-profile"
        />
        <div>
          <p className="font-semibold text-xl dark:text-gray-200">
            {user?.firstName} {user?.lastName}
          </p>
          {user?.role === 'admin' && (
            <p className="text-gray-500 text-sm dark:text-gray-400">
              {' '}
              Administrator{' '}
            </p>
          )}

          <p className="text-gray-500 text-sm font-semibold dark:text-gray-400">
            {user?.email}
          </p>
        </div>
      </div>
    </div>
  )
}

export default UserProfile
