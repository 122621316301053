import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import FadeLoader from 'react-spinners/FadeLoader';
import { useSelector } from 'react-redux';
import { useStateContext } from '../../../contexts/ContextProvider';
import { getCategories, getCategorySubs } from '../../../functions/category';
import { useNavigate } from 'react-router-dom';
import { createUserPost } from '../../../functions/post';
import UserSidebar from '../../../components/sidebar/UserSidebar';
import Navbar from '../../../components/nav/Navbar';
import UserImageUpload from '../../../components/forms/UserImageUpload';
import HeaderProfile from '../../../components/HeaderProfile';
import PostCreateForm from '../../../components/forms/PostCreateForm';
import ImageUpload from '../../../components/forms/ImageUpload';
import {
  CheckCircleFilled,
  ExclamationCircleOutlined,
} from '@ant-design/icons';

const initialState = {
  title: '',
  description: '',
  categories: [],
  category: '',
  subcategories: [],
  images: [],
  tags: [],
};

const UserCreateNewPost = ({ socket }) => {
  const [values, setValues] = useState(initialState);
  const [subOptions, setSubOptions] = useState([]);
  const [showSub, setShowSub] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const { activeMenu } = useStateContext();

  const loadCategories = () => {
    getCategories()
      .then((res) => {
        if (res.status === 'ok') {
          setValues({ ...values, categories: res.data });
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  useEffect(() => {
    loadCategories();
  }, []);

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleCatagoryChange = (e) => {
    e.preventDefault();
    setValues({ ...values, subs: [], category: e.target.value });
    getCategorySubs(e.target.value).then((res) => {
      setSubOptions(res.data);
    });
    setShowSub(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSuccess('');
    setError('');
    if (!values.images.length) {
      return setError('Please upload feature image');
    }
    if (!values.title.length) {
      return setError('Title required');
    }
    if (values.title.length > 100) {
      return setError('Title should not be more than 100 characters');
    }
    if (!values.category.length) {
      return setError('Category required');
    }
    if (!values.subcategories.length) {
      return setError('Sub Category required');
    }
    if (!values.description.length) {
      return setError('Description required');
    }

    const { title, description, category, subcategories, images, tags } =
      values;

    if (socket.current) {
      socket.current.emit('userPost', {
        title,
        description,
        category,
        subcategories,
        images,
        tags,
        userId: user?.id,
      });

      socket.current.on('userPostCreated', ({ msg }) => {
        if (msg === 'ok') {
          setInterval(() => {
            navigate('/user/post');
            window.location.reload();
          }, 2000);
          setSuccess(`${title} is created`);
          setValues({
            title: '',
            description: '',
            categories: [],
            category: '',
            subcategories: [],
            images: [],
            tags: [],
          });
        }
      });

      socket.current.on('userPostCreateFailed', ({ error }) => {
        if (error) {
          setError(`${error}`);
        }
      });
    }

    // createUserPost(data, user.token)
    //   .then((res) => {
    //     if (res.status === 'ok') {
    //       setLoading(false)
    //       setInterval(() => {
    //         navigate('/user/post')
    //         window.location.reload()
    //       }, 1000)
    //       toast.success(`"${title}" is created`)
    //     } else {
    //       setLoading(false)
    //       toast.error(res)
    //     }
    //   })
    //   .catch((err) => {
    //     setLoading(false)
    //     toast.error(err.response.data.message)
    //   })
  };

  return (
    <div className="flex relative">
      {activeMenu ? (
        <div className="w-72 sidebar dark:bg-secondary-dark-bg bg-white ">
          <UserSidebar />
        </div>
      ) : (
        <div className="w-0 dark:bg-secondary-dark-bg">
          <UserSidebar />
        </div>
      )}
      <div
        className={
          activeMenu
            ? 'dark:bg-main-dark-bg  bg-main-bg min-h-screen w-full  '
            : 'bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 '
        }
      >
        <div className="md:static bg-main-bg dark:bg-main-dark-bg navbar w-full">
          <Navbar />
        </div>
        <div className="m-2 md:m-10 mt-10 p-2 md:p-5 bg-white rounded-1xl">
          <HeaderProfile title="Create New Post" />
          {success && (
            <h5 className="text-center auth_error_success mt-3 text-success d-flex justify-content-center align-items-center">
              <CheckCircleFilled
                style={{
                  fontSize: '22px',
                  color: 'green',
                  marginRight: '5px',
                  position: 'relative',
                  top: '-3px',
                }}
              />
              {success}
            </h5>
          )}

          {error && (
            <h5 className="text-center auth_error_success my-3 text-danger d-flex justify-content-center align-items-center">
              {' '}
              <ExclamationCircleOutlined
                style={{
                  fontSize: '20px',
                  color: 'red',
                  marginRight: '5px',
                  position: 'relative',
                  top: '-3px',
                }}
              />{' '}
              {error}
            </h5>
          )}
          <div className="w-96">
            {loading && (
              <div className="flex justify-start items-center">
                <FadeLoader color="#06b6d4" loading={loading} size={20} />
              </div>
            )}
          </div>

          <div className="w-full">
            <div className="md:col-span-5">
              <label>Feature Image</label>
              <ImageUpload
                values={values}
                setValues={setValues}
                setLoading={setLoading}
              />
            </div>

            <PostCreateForm
              handleSubmit={handleSubmit}
              handleChange={handleChange}
              setValues={setValues}
              values={values}
              handleCatagoryChange={handleCatagoryChange}
              subOptions={subOptions}
              showSub={showSub}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserCreateNewPost;
