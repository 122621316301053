import React, { useState, useEffect, useRef } from 'react'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import './style.css'
import { Button } from 'react-bootstrap'
import axios from 'axios'
import Cookies from 'js-cookie'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import FadeLoader from 'react-spinners/FadeLoader'
import {
  CheckCircleFilled,
  ExclamationCircleOutlined,
  LeftOutlined,
} from '@ant-design/icons'
import ForgotFormInput from './ForgotFormInput'

const codeInputs = Array(6).fill('')
let newInputCodeIndex = 0

const ForgotPassword = () => {
  const inputCodeRef = useRef()
  const user = useSelector((state) => state.user)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState('')
  const [error, setError] = useState('')
  const [step, setStep] = useState(1)
  const [otp, setOtp] = useState({ 0: '', 1: '', 2: '', 3: '', 4: '', 5: '' })
  const [nextInputCodeIndex, setNextInputCodeIndex] = useState(0)

  const [values, setValues] = useState({
    email: '',
    password: '',
    cPassword: '',
  })

  const inputLogin = [
    {
      id: 1,
      name: 'email',
      type: 'email',
      placeholder: 'Enter your email address',
      errorMessage: 'It should be a valid email address!',
      label: 'Email address',
      required: true,
    },
  ]

  const inputPassword = [
    {
      id: 1,
      name: 'password',
      type: 'password',
      placeholder: 'Password',
      errorMessage:
        'Password should be 6-20 characters and include at least 1 letter, 1 number and 1 special character!',
      label: 'Password',
      pattern: `^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,20}$`,
      required: true,
    },
    {
      id: 2,
      name: 'cPassword',
      type: 'password',
      placeholder: 'Confirm Password',
      errorMessage: "Passwords don't match!",
      label: 'Confirm Password',
      pattern: values.password,
      required: true,
    },
  ]

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }

  useEffect(() => {
    inputCodeRef?.current?.focus()
  }, [nextInputCodeIndex])

  const isObjValid = (obj) => {
    return Object.values(obj).every((val) => val.trim())
  }

  const handleChangeCodeText = (e, index) => {
    const { value } = e.target

    const cdvalue = value.replace(/\D/g, '')
    const newOtp = { ...otp }
    newOtp[index] = cdvalue
    setOtp(newOtp)

    const lastInputCodeIndex = codeInputs.length - 1

    if (!cdvalue) newInputCodeIndex = index === 0 ? 0 : index - 1
    else
      newInputCodeIndex =
        index === codeInputs.length - 1 ? lastInputCodeIndex : index + 1

    setNextInputCodeIndex(newInputCodeIndex)
  }

  const handleVerification = async (e) => {
    e.preventDefault()
    let val = ''
    Object.values(otp).forEach((v) => {
      val += v
    })
    setLoading(true)
    if (val.length < 6) {
      toast.error('Please fill all code fields')
      setLoading(false)
    } else {
      if (isObjValid(otp)) {
        let val = ''
        Object.values(otp).forEach((v) => {
          val += v
        })

        if (!val) {
          alert('Please enter the code')
          setLoading(false)
          return
        }

        let vData = {
          email: values.email,
          vcode: val,
        }
        try {
          setLoading(true)
          const { data } = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}forgotpasswordcode`,
            vData
          )
          const { message, ...rest } = data
          setError('')
          setSuccess('OTP successfully verified.')
          setTimeout(() => {
            setSuccess('')
            setStep(3)
          }, 2000)
          setLoading(false)
        } catch (error) {
          setLoading(false)
          setSuccess('')
          setError(error.response.data.message)
        }
        setLoading(false)
      }
    }
  }

  const handleUpdatePassword = async (e) => {
    e.preventDefault()
    setError('')
    setSuccess('')

    let val = ''
    Object.values(otp).forEach((v) => {
      val += v
    })

    const { email, password, cPassword } = values

    if (password !== cPassword) {
      return toast.error('Password not matched')
    }

    if (email.length > 0 && password.length > 0) {
      let passData = {
        email,
        vcode: val,
        password,
      }

      try {
        setLoading(true)
        const { data } = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}forgotpasswordupdate`,
          passData
        )
        setError('')
        setSuccess(data.message)
        setTimeout(() => {
          navigate('/login')
          setSuccess('')
        }, 2000)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        setSuccess('')
        setError(error.response.data.message)
      }
    } else {
      setLoading(false)
    }
  }

  const handleSteps = () => {
    switch (step) {
      case 1:
        return (
          <form onSubmit={sendEmailForOtp}>
            {inputLogin.map((input) => (
              <ForgotFormInput
                key={input.id}
                {...input}
                value={values[input.name]}
                onChange={onChange}
              />
            ))}

            <div className="d-grid gap-2 my-5">
              <button
                type="submit"
                className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-cyan-500 rounded-md hover:bg-cyan-500 focus:outline-none focus:bg-cyan-600"
              >
                Send
              </button>
            </div>
          </form>
        )
      case 2:
        return (
          <form onSubmit={handleVerification}>
            <h4 className="text-center auth_verify_text text-muted my-4">
              Please enter OTP we sent you
            </h4>
            <div className="flex-container">
              {codeInputs.map((inp, index) => {
                return (
                  <input
                    type="text"
                    className="block w-full px-4 py-2 mt-2 text-cyan-700 bg-white border rounded-md focus:border-cyan-700 focus:ring-cyan-700 focus:outline-none focus:ring focus:ring-opacity-40"
                    maxLength={1}
                    value={otp[index]}
                    placeholder="0"
                    name="vcode"
                    onChange={(e) => handleChangeCodeText(e, index)}
                    ref={nextInputCodeIndex === index ? inputCodeRef : null}
                    key={index}
                  />
                )
              })}
            </div>
            <br />
            <div className="d-grid gap-2">
              <button
                type="submit"
                className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-cyan-500 rounded-md hover:bg-cyan-500 focus:outline-none focus:bg-cyan-600"
              >
                Verify Code
              </button>
            </div>
            <br />
          </form>
        )
      case 3:
        return (
          <form onSubmit={handleUpdatePassword}>
            <h4 className="text-center auth_verify_text text-muted my-4">
              Create new password
            </h4>

            {inputPassword.map((input) => (
              <ForgotFormInput
                key={input.id}
                {...input}
                value={values[input.name]}
                onChange={onChange}
              />
            ))}

            <br />
            <div className="d-grid gap-2">
              <button
                type="submit"
                className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-cyan-500 rounded-md hover:bg-cyan-500 focus:outline-none focus:bg-cyan-600"
              >
                Verify Code
              </button>
            </div>
            <br />
          </form>
        )
      default:
        return null
    }
  }

  const sendEmailForOtp = async (e) => {
    e.preventDefault()

    setError(null)
    setSuccess(null)
    let vData = {
      email: values.email,
    }
    try {
      setLoading(true)
      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}forgotpassword`,
        vData
      )

      const { message, ...rest } = data
      setError('')
      setSuccess('OTP sent successfully. Please check your email')
      setTimeout(() => {
        setSuccess('')
        setStep(2)
      }, 2000)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setSuccess('')
      setError(error.response.data.message)
    }
  }

  return (
    <div className="relative flex flex-col justify-center min-h-590 overflow-hidden">
      <div className="w-full p-6 m-auto bg-white rounded-md shadow-md sm:max-w-xl lg:max-w-xl">
        <section className="hero container max-w-screen-lg mx-auto pb-2">
          <img
            src="https://res.cloudinary.com/dwn02nfdv/image/upload/v1668858388/user-soccerstatz_ewupwx_xkmqab.png"
            alt="Profile"
            className="auth_member_img mx-auto"
          />
        </section>
        <h3 className="text-2xl font-semibold text-center text-cyan-500 mb-4">
          Forgot Password
        </h3>
        {step === 1 && (
          <p className="text-center text-muted">
            Enter your email to reset password
          </p>
        )}

        {loading && (
          <div className="flex justify-center items-center">
            <FadeLoader color="#06b6d4" loading={loading} size={20} />
          </div>
        )}

        {success && (
          <h5 className="text-center auth_error_success mt-3 text-success d-flex justify-content-center align-items-center">
            <CheckCircleFilled
              style={{ fontSize: '22px', color: 'green', marginRight: '5px' }}
            />
            {success}
          </h5>
        )}
        {error && (
          <h5 className="text-center auth_error_success my-3 text-danger d-flex justify-content-center align-items-center">
            {' '}
            <ExclamationCircleOutlined
              style={{
                fontSize: '20px',
                color: 'red',
                marginRight: '5px',
                position: 'relative',
                top: '-3px',
              }}
            />{' '}
            {error}
          </h5>
        )}

        {handleSteps()}
        <h6 className="text-center">
          <LeftOutlined
            style={{
              fontSize: '15px',
              color: 'gray',
              marginRight: '5px',
              position: 'relative',
              top: '-2px',
            }}
          />{' '}
          Back to{' '}
          <span
            style={{
              display: 'inline-block',
              width: '5px',
            }}
          ></span>
          <Link to="/login" as={NavLink} className="auth_link">
            login
          </Link>{' '}
        </h6>
      </div>
    </div>
  )
}

export default ForgotPassword
