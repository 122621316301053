import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { getCategories } from '../../functions/category'

const initialState = {
  categories: [],
  images: [],
}

const WebSideBar = ({ adverts }) => {
  const [values, setValues] = useState(initialState)

  const loadCategories = () => {
    getCategories()
      .then((res) => {
        if (res.status === 'ok') {
          setValues({ ...values, categories: res.data })
        }
      })
      .catch((err) => {
        console.log(err.response.data.message)
      })
  }

  useEffect(() => {
    loadCategories()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <div className="w-full md:w-1/3 flex flex-col items-center md:pl-6">
        <div className="w-full bg-white flex flex-col shadow my-4 p-6">
          <p className="text-xl font-semibold pb-5">Categories</p>
          {values?.categories?.map((c, i) => {
            return (
              <NavLink to={`/category/${c?.slug}`} key={i}>
                <div className="flex justify-between items-center">
                  <h4 className="text-lg text-dark py-3 hover:text-cyan-500">
                    {c?.name}
                  </h4>
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                    />
                  </svg>
                </div>
              </NavLink>
            )
          })}
        </div>

        {adverts &&
          adverts?.map((ads) => {
            return (
              <div className="w-full bg-white shadow my-4 p-6" key={ads?._id}>
                <p className="text-xl font-semibold pb-5">{ads?.title}</p>
                <div className="w-full">
                  <img
                    className="hover:opacity-75"
                    src={ads?.image}
                    alt="Ads"
                  />
                </div>
                <a
                  href={ads?.btnLink}
                  className="w-full bg-cyan-800 text-white font-bold text-sm uppercase rounded hover:bg-cyan-700 flex items-center justify-center px-2 py-3 mt-6"
                >
                  {ads?.btnText}
                </a>
              </div>
            )
          })}
      </div>
    </>
  )
}

export default WebSideBar
