import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { Link, NavLink } from 'react-router-dom'
import { CheckCircleOutlined } from '@ant-design/icons'
import { MdBlockFlipped } from 'react-icons/md'
import { BsFillCircleFill } from 'react-icons/bs'
import FadeLoader from 'react-spinners/FadeLoader'

import { AiOutlineUserAdd } from 'react-icons/ai'
import LocalSearch from '../../../components/forms/LocalSearch'
import { useSelector } from 'react-redux'
import { getAllUsers, updateUserIsBlocked } from '../../../functions/users'
import { useStateContext } from '../../../contexts/ContextProvider'
import AdminSidebar from '../../../components/sidebar/AdminSidebar'
import Navbar from '../../../components/nav/Navbar'
import HeaderProfile from '../../../components/HeaderProfile'

const AdminUser = ({ connectedusers }) => {
  const [loading, setLoading] = useState(false)
  const [keyword, setKeyword] = useState('')
  const [users, setUsers] = useState([])
  const user = useSelector((state) => state.user)
  const { activeMenu } = useStateContext()

  const getUserOnline = connectedusers.map((user) => user.userId)

  const usersExist = users.map((user) => user._id)

  var oneIDs = getUserOnline.map((user) => {
    return user
  })

  var result = usersExist.filter(function (a) {
    return oneIDs.indexOf(a) === -1
  })

  useEffect(() => {
    loadAllUsers()
  }, [])

  const loadAllUsers = () => {
    setLoading(true)
    getAllUsers(user.token)
      .then((res) => {
        if (res.status === 'ok') {
          setUsers(res.data)
          setLoading(false)
        }
      })
      .catch((err) => {
        setLoading(false)
        toast.error(err.response.data.message)
      })
  }

  const searched = (keyword) => (u) => u.email.toLowerCase().includes(keyword)

  const handleBlock = async (id, blocked) => {
    console.log(id, blocked)
    let data = {
      id: id,
      isBlocked: blocked,
    }
    setLoading(true)
    updateUserIsBlocked(data, user.token)
      .then((res) => {
        if (res.status === 'ok') {
          setLoading(false)
          loadAllUsers()
          toast.success(`Settings is updated`)
        } else {
          setLoading(false)
          toast.error(res)
        }
      })
      .catch((err) => {
        setLoading(false)
        toast.error(err.response.message)
      })
  }
  return (
    <div className="flex relative">
      {activeMenu ? (
        <div className="w-72 sidebar dark:bg-secondary-dark-bg bg-white ">
          <AdminSidebar />
        </div>
      ) : (
        <div className="w-0 dark:bg-secondary-dark-bg">
          <AdminSidebar />
        </div>
      )}
      <div
        className={
          activeMenu
            ? 'dark:bg-main-dark-bg  bg-main-bg min-h-screen w-full  '
            : 'bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 '
        }
      >
        <div className="md:static bg-main-bg dark:bg-main-dark-bg navbar w-full">
          <Navbar />
        </div>
        <div className="m-2 md:m-10 mt-10 p-2 md:p-5 bg-white rounded-1xl">
          <HeaderProfile title="Users" />
          {loading && (
            <div className="flex justify-center items-center">
              <FadeLoader color="#06b6d4" loading={loading} size={20} />
            </div>
          )}
          <div className="w-96">
            <NavLink
              to="/admin/users/create-user"
              className="bg-cyan-600 text-white p-2 rounded hover:text-neutral-50 px-4 py-2 tracking-wide transition-colors duration-200 transform"
            >
              Register User
            </NavLink>
          </div>
          <br />
          <LocalSearch keyword={keyword} setKeyword={setKeyword} />

          {!loading && (
            <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
              <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
                <table className="min-w-full leading-normal">
                  <tbody>
                    {users?.filter(searched(keyword))?.map((u) => (
                      <tr key={u._id}>
                        <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm 3/5">
                          <div className="flex items-center">
                            <div className="flex-shrink-0 w-10 h-10 hidden sm:table-cell">
                              <img
                                className="w-full h-full rounded-full"
                                src={u?.profilePic}
                                alt="Category"
                              />
                            </div>
                            <div className="ml-3">
                              <p className="text-gray-900 whitespace-no-wrap">
                                {u.email}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td className="px-2 border-b border-gray-200 bg-white text-sm">
                          <div className="flex justify-end items-center">
                            <div className="mx-2">
                              {getUserOnline.includes(u._id) ? (
                                <BsFillCircleFill color="green" />
                              ) : (
                                <BsFillCircleFill color="grey" />
                              )}
                            </div>

                            <div className="mx-2">
                              {u.isBlocked ? (
                                <MdBlockFlipped
                                  style={{
                                    color: 'red',
                                    position: 'relative',
                                  }}
                                />
                              ) : (
                                <CheckCircleOutlined
                                  style={{
                                    color: 'green',
                                    position: 'relative',
                                    bottom: '3px',
                                  }}
                                />
                              )}
                            </div>

                            <div className="mx-2">
                              {u.isBlocked ? (
                                <button
                                  onClick={() => handleBlock(u._id, false)}
                                  className="w-20   tracking-wide text-white transition-colors duration-200 transform bg-green-500 rounded-md "
                                >
                                  Unblock
                                </button>
                              ) : (
                                <button
                                  onClick={() => handleBlock(u._id, true)}
                                  className="w-20  tracking-wide text-white transition-colors duration-200 transform bg-red-500 rounded-md hover:bg-red-700 focus:outline-none focus:bg-red-500"
                                >
                                  Block
                                </button>
                              )}
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default AdminUser
