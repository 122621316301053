import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { NavLink } from 'react-router-dom'
import {
  EditOutlined,
  DeleteOutlined,
  CheckCircleFilled,
  PauseCircleFilled,
} from '@ant-design/icons'
import FadeLoader from 'react-spinners/FadeLoader'
import { getAllUserPost, removeUserPost } from '../../../functions/post'
import { useStateContext } from '../../../contexts/ContextProvider'
import { useSelector } from 'react-redux'
import Navbar from '../../../components/nav/Navbar'
import HeaderProfile from '../../../components/HeaderProfile'
import LocalSearch from '../../../components/forms/LocalSearch'
import UserSidebar from '../../../components/sidebar/UserSidebar'
import { TooltipComponent } from '@syncfusion/ej2-react-popups'
import Pagination from '../../../components/pagination/pagination'
const UserPost = ({ socket }) => {
  const [loading, setLoading] = useState(false)
  const [keyword, setKeyword] = useState('')
  const [posts, setPosts] = useState([])
  const user = useSelector((state) => state.user)
  const { activeMenu } = useStateContext()
  const [currentPage, setCurrentPage] = useState(1)
  const [postsPerPage] = useState(9)

  const loadAllPosts = () => {
    setLoading(true)
    getAllUserPost(user?.token)
      .then((res) => {
        if (res.status === 'ok') {
          setPosts(res.data)
          setLoading(false)
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message)
        setLoading(false)
      })
  }

  useEffect(() => {
    loadAllPosts()
  }, [])

  useEffect(() => {
    if (socket.current) {
      socket.current.on('userPostDeletedNotification', ({ title }) => {
        if (title) {
          loadAllPosts()
        }
      })
    }
  }, [])

  const searched = (keyword) => (p) => p.title.toLowerCase().includes(keyword)

  const handleRemove = async (title, slug) => {
    if (window.confirm('Are you sure you want to delete?')) {
      setLoading(true)
      removeUserPost(slug, user.token)
        .then((res) => {
          if (res.status === 'ok') {
            setLoading(false)
            loadAllPosts()
            toast.success(`"${title}" is deleted`)
            setInterval(() => {
              window.location.reload()
            }, 1000)
          } else {
            setLoading(false)
            toast.error(res)
          }
        })
        .catch((err) => {
          setLoading(false)
          toast.error(err.response.data.message)
        })
    }
  }

  //Get current posts
  const indexOfLastPost = currentPage * postsPerPage
  const indexOfFirstPost = indexOfLastPost - postsPerPage
  const currentPosts = posts?.slice(indexOfFirstPost, indexOfLastPost)
  const howManyPages = Math?.ceil(posts.length / postsPerPage)

  return (
    <div className="flex relative">
      {activeMenu ? (
        <div className="w-72 sidebar dark:bg-secondary-dark-bg bg-white ">
          <UserSidebar />
        </div>
      ) : (
        <div className="w-0 dark:bg-secondary-dark-bg">
          <UserSidebar />
        </div>
      )}
      <div
        className={
          activeMenu
            ? 'dark:bg-main-dark-bg  bg-main-bg min-h-screen w-full  '
            : 'bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 '
        }
      >
        <div className="md:static bg-main-bg dark:bg-main-dark-bg navbar w-full">
          <Navbar />
        </div>
        <div className="m-2 md:m-10 mt-10 p-2 md:p-5 bg-white rounded-1xl">
          <HeaderProfile title={`Post (${posts?.length})`} />
          {loading && (
            <div className="flex justify-center items-center">
              <FadeLoader color="#06b6d4" loading={loading} size={20} />
            </div>
          )}
          {!loading && (
            <>
              <div className="w-96">
                <NavLink
                  to="/user/post/new"
                  className="bg-cyan-600 text-white p-2 rounded hover:text-neutral-50 px-4 py-2 tracking-wide transition-colors duration-200 transform"
                >
                  Add Post
                </NavLink>
              </div>
              <br />
              {posts?.length > 0 && (
                <LocalSearch keyword={keyword} setKeyword={setKeyword} />
              )}

              <div className="grid grid-cols-1 xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-5">
                {currentPosts?.filter(searched(keyword))?.map((p) => (
                  <div
                    className="rounded overflow-hidden shadow-lg"
                    key={p._id}
                  >
                    <img
                      className="w-full"
                      src={p.images[0]?.url}
                      alt="Post Image"
                      style={{ height: '245px' }}
                    />
                    <div className="px-6 py-4">
                      <div className="font-bold text-sm mb-2">
                        {p?.title.substring(0, 50).trimEnd() + '...'}
                      </div>
                    </div>
                    <div className="px-6 pt-4 pb-6 text-center">
                      <span className="mx-5">
                        {p.published === 'published' ? (
                          <CheckCircleFilled
                            className="text-green-500"
                            style={{
                              fontSize: '20px',
                            }}
                          />
                        ) : (
                          <PauseCircleFilled
                            className="text-orange-500"
                            style={{
                              fontSize: '20px',
                            }}
                          />
                        )}
                      </span>
                      <NavLink to={`/user/post/${p.slug}`}>
                        <span className="mx-5">
                          <EditOutlined
                            className="text-green-500"
                            style={{
                              fontSize: '20px',
                            }}
                          />
                        </span>
                      </NavLink>
                      <span
                        onClick={() => handleRemove(p.title, p.slug)}
                        className="mx-5"
                      >
                        <DeleteOutlined
                          className="text-red-500"
                          style={{
                            fontSize: '20px',
                          }}
                        />
                      </span>
                    </div>
                  </div>
                ))}
              </div>

              {currentPosts && currentPosts?.length > 0 && (
                <div className="my-6">
                  <Pagination
                    pages={howManyPages}
                    setCurrentPage={setCurrentPage}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default UserPost
