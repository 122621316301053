import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import FadeLoader from 'react-spinners/FadeLoader';
import { getCategories, getCategorySubs } from '../../../functions/category';
import { useSelector } from 'react-redux';
import ImageUpload from '../../../components/forms/ImageUpload';
import PostCreateForm from '../../../components/forms/PostCreateForm';
import { createPost } from '../../../functions/post';
import { useNavigate } from 'react-router-dom';
import { useStateContext } from '../../../contexts/ContextProvider';
import AdminSidebar from '../../../components/sidebar/AdminSidebar';
import Navbar from '../../../components/nav/Navbar';
import HeaderProfile from '../../../components/HeaderProfile';

const initialState = {
  title: '',
  description: '',
  categories: [],
  category: '',
  subcategories: [],
  images: [],
  tags: [],
};

const AdminCreateNewPost = () => {
  const [values, setValues] = useState(initialState);
  const [subOptions, setSubOptions] = useState([]);
  const [showSub, setShowSub] = useState(false);
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const { activeMenu } = useStateContext();

  const loadCategories = () => {
    getCategories()
      .then((res) => {
        if (res.status === 'ok') {
          setValues({ ...values, categories: res.data });
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  useEffect(() => {
    loadCategories();
  }, []);

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleCatagoryChange = (e) => {
    e.preventDefault();
    setValues({ ...values, subs: [], category: e.target.value });
    getCategorySubs(e.target.value).then((res) => {
      setSubOptions(res.data);
    });
    setShowSub(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!values.images.length) {
      return toast.error('Please upload feature image');
    }
    if (!values.title.length) {
      return toast.error('Title required');
    }
    if (values.title.length > 100) {
      return toast.error('Title should not be more than 100 characters');
    }
    if (!values.category.length) {
      return toast.error('Category required');
    }
    if (!values.subcategories.length) {
      return toast.error('Sub Category required');
    }
    if (!values.description.length) {
      return toast.error('Description required');
    }

    const { title, description, category, subcategories, images, tags } =
      values;

    let data = {
      title,
      description,
      category,
      subcategories,
      images,
      tags,
    };

    createPost(data, user.token)
      .then((res) => {
        if (res.status === 'ok') {
          setLoading(false);
          setInterval(() => {
            navigate('/admin/post');
            window.location.reload();
          }, 1000);
          toast.success(`"${title}" is created`);
        } else {
          setLoading(false);
          toast.error(res);
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response.data.message);
      });
  };

  return (
    <div className="flex relative">
      {activeMenu ? (
        <div className="w-72 sidebar dark:bg-secondary-dark-bg bg-white ">
          <AdminSidebar />
        </div>
      ) : (
        <div className="w-0 dark:bg-secondary-dark-bg">
          <AdminSidebar />
        </div>
      )}
      <div
        className={
          activeMenu
            ? 'dark:bg-main-dark-bg  bg-main-bg min-h-screen w-full  '
            : 'bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 '
        }
      >
        <div className="md:static bg-main-bg dark:bg-main-dark-bg navbar w-full">
          <Navbar />
        </div>
        <div className="m-2 md:m-10 mt-10 p-2 md:p-5 bg-white rounded-1xl">
          <HeaderProfile title="Create New Post" />
          <div className="w-96">
            {loading && (
              <div className="flex justify-start items-center">
                <FadeLoader color="#06b6d4" loading={loading} size={20} />
              </div>
            )}
          </div>

          <div className="w-full">
            <div className="md:col-span-5">
              <label>Feature Image</label>
              <ImageUpload
                values={values}
                setValues={setValues}
                setLoading={setLoading}
              />
            </div>

            <PostCreateForm
              handleSubmit={handleSubmit}
              handleChange={handleChange}
              setValues={setValues}
              values={values}
              handleCatagoryChange={handleCatagoryChange}
              subOptions={subOptions}
              showSub={showSub}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminCreateNewPost;
