import React from 'react'
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom'
import {
  AiOutlineCalendar,
  AiOutlineShoppingCart,
  AiOutlineAreaChart,
  AiOutlineBarChart,
  AiOutlineStock,
} from 'react-icons/ai'
import { LogoutOutlined } from '@ant-design/icons'
import { googleLogout } from '@react-oauth/google'
import {
  FiShoppingBag,
  FiEdit,
  FiPieChart,
  FiBarChart,
  FiCreditCard,
  FiStar,
  FiShoppingCart,
} from 'react-icons/fi'
import {
  BsKanban,
  BsBarChart,
  BsBoxSeam,
  BsCurrencyDollar,
  BsShield,
  BsChatLeft,
  BsFileEarmarkPost,
} from 'react-icons/bs'
import { BiColorFill } from 'react-icons/bi'
import { IoMdContacts } from 'react-icons/io'
import { RiContactsLine, RiStockLine } from 'react-icons/ri'
import { MdOutlineSupervisorAccount, MdSettingsSuggest } from 'react-icons/md'
import { HiOutlineRefresh } from 'react-icons/hi'
import { TiTick } from 'react-icons/ti'
import { GiLouvrePyramid } from 'react-icons/gi'
import { GrLocation } from 'react-icons/gr'
import { MdOutlineCancel } from 'react-icons/md'
import { TooltipComponent } from '@syncfusion/ej2-react-popups'
import Cookies from 'js-cookie'
import { useStateContext } from '../../contexts/ContextProvider'
import { useDispatch, useSelector } from 'react-redux'
import { AiOutlineDashboard } from 'react-icons/ai'

const UserSidebar = () => {
  const { activeMenu, setActiveMenu, screenSize } = useStateContext()
  const user = useSelector((state) => state.user)
  const location = useLocation()
  const dispatch = useDispatch()

  const navigation = useNavigate()

  const links = [
    {
      id: 1,
      path: '/user/dashboard',
      name: 'Dashboard',
      icon: <AiOutlineDashboard />,
    },
    {
      id: 2,
      path: '/user/post',
      name: 'Post',
      icon: <BsFileEarmarkPost />,
    },
    {
      id: 3,
      path: '/user/settings',
      name: 'Settings',
      icon: <MdSettingsSuggest />,
    },
  ]

  const logout = () => {
    Cookies.remove('user')
    dispatch({
      type: 'LOGOUT',
      payload: null,
    })
    navigation('/login')
  }

  const logoutGoogle = () => {
    googleLogout()
    Cookies.remove('user')
    dispatch({
      type: 'LOGOUT',
      payload: null,
    })
    navigation('/login')
  }

  const handleCloseSideBar = () => {
    if (activeMenu !== undefined && screenSize <= 900) {
      setActiveMenu(false)
    }
  }

  const activeLink =
    'flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg  text-white  text-md m-2'
  const normalLink =
    'flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-md text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray m-2'

  return (
    <div className="ml-3 h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10">
      {activeMenu && (
        <>
          <div className="flex justify-end">
            <TooltipComponent content="Menu" position="BottomCenter">
              <button
                type="button"
                onClick={() => setActiveMenu(!activeMenu)}
                className="text-xl rounded-full p-3 mt-4 block md:hidden"
              >
                <MdOutlineCancel />
              </button>
            </TooltipComponent>
          </div>
          <div className="mt-5">
            {links.map((link) => {
              return (
                <NavLink
                  to={`${link.path}`}
                  key={link.id}
                  onClick={handleCloseSideBar}
                  className={({ isActive }) =>
                    isActive
                      ? `${activeLink} link_dash_act`
                      : `${normalLink} link_dash`
                  }
                  style={({ isActive }) => ({
                    backgroundColor: isActive ? 'rgb(3, 201, 215)' : '',
                  })}
                >
                  {link.icon}
                  <span className="capitalize ">{link.name}</span>
                </NavLink>
              )
            })}
            <NavLink
              to="/"
              onClick={() => (user?.google ? logoutGoogle() : logout())}
              className={normalLink}
            >
              <LogoutOutlined />
              <span className="capitalize ">Logout</span>
            </NavLink>
          </div>
        </>
      )}
    </div>
  )
}

export default UserSidebar
