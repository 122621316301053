import React, { useRef, useState } from 'react';
import { Select } from 'antd';
import { Editor } from '@tinymce/tinymce-react';
import FadeLoader from 'react-spinners/FadeLoader';

const { Option } = Select;

const AdsForm = ({
  handleSubmit,
  handleChange,
  setValues,
  values,
  loading,
}) => {
  const { title, btnText, btnLink } = values;

  return (
    <form onSubmit={handleSubmit}>
      <div className="md-col-span-5">
        <label>Title</label>
        <input
          type="text"
          name="title"
          className="block w-full mb-4 px-4 py-2 mt-2 text-cyan-700 bg-white border rounded-md focus:border-cyan-700 focus:outline-none"
          value={title}
          onChange={handleChange}
        />
      </div>

      <div className="md-col-span-5">
        <label>Button text</label>
        <input
          type="text"
          name="btnText"
          className="block w-full mb-4 px-4 py-2 mt-2 text-cyan-700 bg-white border rounded-md focus:border-cyan-700 focus:outline-none"
          value={btnText}
          onChange={handleChange}
        />
      </div>

      <div className="md-col-span-5">
        <label>Button link</label>
        <input
          type="text"
          name="btnLink"
          className="block w-full mb-4 px-4 py-2 mt-2 text-cyan-700 bg-white border rounded-md focus:border-cyan-700 focus:outline-none"
          value={btnLink}
          onChange={handleChange}
        />
      </div>

      <div className="d-grid gap-2 my-2">
        <button
          type="submit"
          className={`w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform ${
            loading
              ? 'bg-gray-500 hover:bg-gray-500'
              : 'bg-cyan-500 hover:bg-cyan-500'
          }  rounded-md  focus:outline-none focus:bg-cyan-600`}
          disabled={loading}
        >
          Save
        </button>
      </div>
    </form>
  );
};

export default AdsForm;
