import axios from 'axios'
import catchErrors from '../utils/catchErrors'

export const createPost = async (newdata, authtoken) => {
  console.log(newdata)
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}post`,
      { newdata },
      {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
    return { status: 'ok', data }
  } catch (error) {
    return error.response.data.message
  }
}

export const createUserPost = async (newdata, authtoken) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}user/post`,
      { newdata },
      {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
    return { status: 'ok', data }
  } catch (error) {
    return error.response.data.message
  }
}

export const getPostsByCount = async (count) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}posts/${count}`
    )
    return { status: 'ok', data }
  } catch (error) {
    return error.response.data.message
  }
}

export const getPostsSlider = async (count) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}slider/posts/${count}`
    )
    return { status: 'ok', data }
  } catch (error) {
    return error.response.data.message
  }
}

export const getPostsByCategory = async (category) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}post/category/${category}`
    )
    return { status: 'ok', data }
  } catch (error) {
    return error.response.data.message
  }
}

export const getPostsBySubCategory = async (category) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}post/sub-category/${category}`
    )
    return { status: 'ok', data }
  } catch (error) {
    return error.response.data.message
  }
}

export const removePost = async (slug, authtoken) => {
  try {
    axios.delete(`${process.env.REACT_APP_BACKEND_URL}post/${slug}`, {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
    return { status: 'ok' }
  } catch (error) {
    return error.response.data.message
  }
}

export const removeUserPost = async (slug, authtoken) => {
  try {
    const { data } = axios.delete(
      `${process.env.REACT_APP_BACKEND_URL}user/post/${slug}`,
      {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
    return { status: 'ok', data }
  } catch (error) {
    return error.response.data.message
  }
}

export const getPost = async (slug) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}post/${slug}`
    )
    return { status: 'ok', data }
  } catch (error) {
    return error.response.data.message
  }
}

export const getAllPost = async (setError) => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}posts`)
    setError(null)
    return res
  } catch (error) {
    const errorMsg = catchErrors(error)
    setError(errorMsg)
  }
}

export const getAllUserPost = async (authtoken) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}user/posts`,
      {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
    return { status: 'ok', data }
  } catch (error) {
    return error.response.data.message
  }
}

export const updatePost = async (slug, newdata, authtoken) => {
  try {
    const { data } = await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}post/${slug}`,
      { newdata },
      {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
    return { status: 'ok', data }
  } catch (error) {
    return error.response.data.message
  }
}

export const likePost = async (
  token,
  postId,
  userId,
  setLikes,
  like = true
) => {
  try {
    if (like) {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}like/${postId}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      setLikes((prev) => [...prev, { user: userId }])
    } else if (!like) {
      await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}unlike/${postId}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      setLikes((prev) => prev.filter((like) => like.user !== userId))
    }
  } catch (error) {
    return error.response.data.message
  }
}

export const postComment = async (
  authtoken,
  postId,
  user,
  text,
  setComments,
  setText
) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}comment/${postId}`,
      { text },
      {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
    const newComment = {
      _id: res.data,
      user,
      text,
      date: Date.now(),
    }

    setComments((prev) => [newComment, ...prev])
    setText('')
  } catch (error) {
    return error.response.data.message
  }
}

export const deleteComment = async (
  authtoken,
  postId,
  commentId,
  setComments
) => {
  try {
    await axios.delete(
      `${process.env.REACT_APP_BACKEND_URL}comment/${postId}/${commentId}`,
      {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )

    setComments((prev) => prev.filter((comment) => comment._id !== commentId))
  } catch (error) {
    return error.response.data.message
  }
}

export const updateUserPost = async (slug, newdata, authtoken) => {
  try {
    const { data } = await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}user/post/${slug}`,
      { newdata },
      {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
    return { status: 'ok', data }
  } catch (error) {
    return error.response.data.message
  }
}

export const updatePostStatus = async (newdata, slug, authtoken) => {
  try {
    const { data } = await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}post-status/${slug}`,
      { newdata },
      {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
    return { status: 'ok', data }
  } catch (error) {
    return error.response.data.message
  }
}

export const getProductsCount = async () =>
  await axios.get(`${process.env.REACT_APP_API}/products/total`)

export const getRelated = async (productId) =>
  await axios.get(`${process.env.REACT_APP_API}/product/related/${productId}`)

export const fetchProductsByFilter = async (arg) =>
  await axios.post(`${process.env.REACT_APP_API}/search/filters`, arg)
