import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import CategoryForm from '../../../components/forms/CategoryForm'
import { getCategory, updateCategory } from '../../../functions/category'
import { toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'
import ImageUpload from '../../../components/forms/ImageUpload'
import FadeLoader from 'react-spinners/FadeLoader'
import { useStateContext } from '../../../contexts/ContextProvider'
import HeaderProfile from '../../../components/HeaderProfile'
import Navbar from '../../../components/nav/Navbar'
import AdminSidebar from '../../../components/sidebar/AdminSidebar'

const initialState = {
  images: [],
}

const AdminCategoryEdit = () => {
  const { slug } = useParams()
  const navigate = useNavigate()
  const user = useSelector((state) => state.user)
  const [name, setName] = useState('')
  const [loading, setLoading] = useState(false)
  const [values, setValues] = useState(initialState)
  const { activeMenu } = useStateContext()

  const loadCategories = () => {
    getCategory(slug)
      .then((res) => {
        if (res.status === 'ok') {
          setValues({ ...values, images: res.data.category?.catImg })
          setName(res.data.category?.name)
        }
      })
      .catch((err) => {
        toast.error(err.response.data?.message)
      })
  }

  useEffect(() => {
    loadCategories()
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault()

    if (!values.images.length) {
      return toast.error('Please upload image')
    }

    setLoading(true)
    let data = {
      name,
      catImg: values.images,
    }
    updateCategory(slug, data, user.token)
      .then((res) => {
        if (res.status === 'ok') {
          setLoading(false)
          toast.success(`"${name}" is updated`)
          loadCategories()
          navigate('/admin/category')
        } else {
          setLoading(false)
          toast.error(res)
        }
      })
      .catch((err) => {
        setLoading(false)
        toast.error(err.response.data.message)
      })
  }

  return (
    <div className="flex relative">
      {activeMenu ? (
        <div className="w-72 sidebar dark:bg-secondary-dark-bg bg-white ">
          <AdminSidebar />
        </div>
      ) : (
        <div className="w-0 dark:bg-secondary-dark-bg">
          <AdminSidebar />
        </div>
      )}
      <div
        className={
          activeMenu
            ? 'dark:bg-main-dark-bg  bg-main-bg min-h-screen w-full  '
            : 'bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 '
        }
      >
        <div className="md:static bg-main-bg dark:bg-main-dark-bg navbar w-full">
          <Navbar />
        </div>
        <div className="m-2 md:m-10 mt-10 p-2 md:p-5 bg-white rounded-1xl">
          <HeaderProfile title="Edit Category" />
          <div className="w-96">
            {loading && (
              <div className="flex justify-start items-center">
                <FadeLoader color="#06b6d4" loading={loading} size={20} />
              </div>
            )}
            <div className="md:col-span-5">
              <label>Category Image</label>
              <ImageUpload
                values={values}
                setValues={setValues}
                setLoading={setLoading}
              />
            </div>

            <CategoryForm
              handleSubmit={handleSubmit}
              name={name}
              setName={setName}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminCategoryEdit
