import React, { useState } from 'react'
import { toast } from 'react-toastify'
import FadeLoader from 'react-spinners/FadeLoader'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import axios from 'axios'
import { CheckCircleFilled, ExclamationCircleOutlined } from '@ant-design/icons'
import { useStateContext } from '../../../contexts/ContextProvider'
import AdminSidebar from '../../../components/sidebar/AdminSidebar'
import Navbar from '../../../components/nav/Navbar'
import HeaderProfile from '../../../components/HeaderProfile'

const initialState = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
}

const AdminCreateUser = () => {
  const [values, setValues] = useState(initialState)
  const user = useSelector((state) => state.user)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const navigate = useNavigate()
  const { activeMenu } = useStateContext()

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }

  const onSubmit = async (e) => {
    e.preventDefault()

    setError('')
    setSuccess('')
    const { firstName, lastName, email, password } = values

    if (
      firstName.length > 0 &&
      lastName.length > 0 &&
      email.length > 0 &&
      password.length > 0
    ) {
      let regData = {
        firstName,
        lastName,
        email,
        password,
      }

      try {
        setLoading(true)
        const { data } = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}admin-create-user`,
          { regData },
          {
            headers: {
              Authorization: `Bearer ${user?.token}`,
            },
          }
        )
        setError('')
        setSuccess(data.message)
        setTimeout(() => {
          setSuccess('')
          navigate('/admin/users')
        }, 2000)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        setSuccess('')
        setError(error.response.data.message)
      }
    } else {
      setLoading(false)
    }
  }

  return (
    <div className="flex relative">
      {activeMenu ? (
        <div className="w-72 sidebar dark:bg-secondary-dark-bg bg-white ">
          <AdminSidebar />
        </div>
      ) : (
        <div className="w-0 dark:bg-secondary-dark-bg">
          <AdminSidebar />
        </div>
      )}
      <div
        className={
          activeMenu
            ? 'dark:bg-main-dark-bg  bg-main-bg min-h-screen w-full  '
            : 'bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 '
        }
      >
        <div className="md:static bg-main-bg dark:bg-main-dark-bg navbar w-full">
          <Navbar />
        </div>
        <div className="m-2 md:m-10 mt-10 p-2 md:p-5 bg-white rounded-1xl">
          <HeaderProfile title="Add User" />
          {loading && (
            <div className="flex justify-start items-center">
              <FadeLoader color="#06b6d4" loading={loading} size={20} />
            </div>
          )}
          <div className="w-full">
            {success && (
              <h5 className="text-left auth_error_success mt-3 text-success d-flex justify-content-center align-items-center">
                <CheckCircleFilled
                  style={{
                    fontSize: '22px',
                    color: 'green',
                    marginRight: '5px',
                    position: 'relative',
                    top: '-3px',
                  }}
                />
                {success}
              </h5>
            )}
            {error && (
              <h5 className="text-left auth_error_success my-3 text-danger d-flex justify-content-center align-items-center">
                {' '}
                <ExclamationCircleOutlined
                  style={{
                    fontSize: '20px',
                    color: 'red',
                    marginRight: '5px',
                    position: 'relative',
                    top: '-3px',
                  }}
                />{' '}
                {error}
              </h5>
            )}
          </div>

          <div className="sm:w-full xs:w-full md:w-full lg:w-3/4">
            <form onSubmit={onSubmit}>
              <input
                type="text"
                name="firstName"
                placeholder="First name"
                className="mb-3 block w-full px-4 py-2 mt-2 text-cyan-700 bg-white border rounded-md focus:border-cyan-700 focus:outline-none"
                onChange={onChange}
                required
              />

              <input
                type="text"
                name="lastName"
                placeholder="Last name"
                className="mb-3 block w-full px-4 py-2 mt-2 text-cyan-700 bg-white border rounded-md focus:border-cyan-700 focus:outline-none"
                onChange={onChange}
                required
              />

              <input
                type="email"
                name="email"
                placeholder="User email"
                className="mb-3 block w-full px-4 py-2 mt-2 text-cyan-700 bg-white border rounded-md focus:border-cyan-700 focus:outline-none"
                onChange={onChange}
                required
              />

              <input
                type="password"
                name="password"
                placeholder="Password"
                className="mb-3 block w-full px-4 py-2 mt-2 text-cyan-700 bg-white border rounded-md focus:border-cyan-700 focus:outline-none"
                onChange={onChange}
                required
              />

              <div className="mt-6">
                <button
                  type="submit"
                  className="w-36 px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-cyan-500 rounded-md hover:bg-cyan-500 focus:outline-none focus:bg-cyan-600"
                >
                  Register
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminCreateUser
