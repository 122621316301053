import React, { useState, useEffect } from 'react';

import { getPostsSlider } from '../../functions/post';
import parse from 'html-react-parser';
import { NavLink } from 'react-router-dom';
import { smartTrim } from '../../utils/helpers';
import moment from 'moment';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Navigation } from 'swiper';
import 'swiper/css/navigation';
import 'swiper/css';
import WebSideBar from '../Sidebar/WebSideBar';
import FadeLoader from 'react-spinners/FadeLoader';

const initialState = {
  categories: [],
  images: [],
};

const Home = ({ adverts }) => {
  const [posts, setPosts] = useState([]);
  const [homePosts, setHomePosts] = useState([]);
  const [loading, setLoading] = useState(false);

  const loadSliderPosts = () => {
    setLoading(true);
    getPostsSlider(15)
      .then((res) => {
        if (res.status === 'ok') {
          setPosts(res.data);
          setHomePosts(res.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.error(err.response.data.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    loadSliderPosts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  SwiperCore.use([Autoplay]);

  const post1 = posts && posts[0];
  const post1Excerpt = smartTrim(
    post1?.description
      .replace(/<img[^>"']*((("[^"]*")|('[^']*'))[^"'>]*)*>/g, '')
      .replace(/<a\b[^>]*>(.*?)<\/a>/g, ''),
    350,
    ' ',
    ' ...'
  );
  const post2 = posts && posts[1];

  const post3 = posts && posts[2];

  return (
    <>
      {loading ? (
        <div className="flex justify-center items-center">
          <FadeLoader color="#06b6d4" loading={loading} size={20} />
        </div>
      ) : (
        <>
          <div className="grid lg:grid-cols-3 md:grid-cols-1 sm:grid-cols-1">
            <div className="bg-zinc-900 shadow-xl md:h-[40rem] sm:h-[20rem] lg:row-span-2 lg:col-span-2 md:row-span-1 md:col-span-1 sm:grid-cols-2 sm:col-span-2">
              <div className="w-full select-none relative">
                <div className="relative">
                  <NavLink
                    to={`/post/${post1?.slug}`}
                    className="hover:opacity-90"
                  >
                    <img
                      src={post1?.images[0]?.url}
                      alt={post1?.title}
                      className="w-full md:h-[40rem] lg:h-[40rem] sm:h-[25rem]"
                    />
                  </NavLink>
                  <div className="w-full flex justify-start items-center absolute bottom-0  mt-2 md:h-40 sm:h-24  bg-stone-900 opacity-60">
                    <NavLink
                      to={`/post/${post1?.slug}`}
                      className="text-md text-white md:text-lg sm:text-sm hover:cursor-pointer py-5 px-4"
                    >
                      <div style={{ fontSize: '20px', marginBottom: '10px' }}>
                        {post1?.title}
                      </div>

                      <div
                        className="hidden md:block lg:block"
                        style={{ fontSize: '16px' }}
                      >
                        {parse(`${post1Excerpt}`)}
                      </div>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-zinc-900 shadow-xl md:h-[20rem]">
              <div className="w-full select-none relative">
                <div className="relative">
                  <NavLink
                    to={`/post/${post2?.slug}`}
                    className="hover:opacity-90"
                  >
                    <img
                      src={post2?.images[0]?.url}
                      alt={post2?.title}
                      className="w-full md:h-[30rem] lg:h-[20rem] sm:h-[25rem]"
                    />
                  </NavLink>
                  <div className="w-full flex justify-start items-center absolute bottom-0  mt-2 md:h-[4rem] sm:h-24  bg-stone-900 opacity-60">
                    <NavLink
                      to={`/post/${post2?.slug}`}
                      className="text-sm text-white md:text-lg sm:text-sm hover:cursor-pointer py-5 px-4"
                    >
                      <div style={{ fontSize: '16px', marginBottom: '10px' }}>
                        {post2?.title}
                      </div>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-zinc-900 shadow-xl md:h-[20rem]">
              <div className="w-full select-none relative">
                <div className="relative">
                  <NavLink
                    to={`/post/${post3?.slug}`}
                    className="hover:opacity-90"
                  >
                    <img
                      src={post3?.images[0]?.url}
                      alt={post3?.title}
                      className="w-full md:h-[30rem] lg:h-[20rem] sm:h-[25rem]"
                    />
                  </NavLink>
                  <div className="w-full flex justify-start items-center absolute bottom-0  mt-2 md:h-[4rem] sm:h-24  bg-stone-900 opacity-60">
                    <NavLink
                      to={`/post/${post3?.slug}`}
                      className="text-sm text-white md:text-lg sm:text-sm hover:cursor-pointer py-5 px-4"
                    >
                      <div style={{ fontSize: '16px', marginBottom: '10px' }}>
                        {post3?.title}
                      </div>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container mx-auto flex flex-wrap py-6">
            {homePosts.length > 0 && (
              <>
                <section className="w-full md:w-2/3 flex flex-col items-center">
                  <div className="grid grid-flow-row xs:grid-cols-12 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-6 gap-6">
                    {homePosts?.slice(3, 9)?.map((p, i) => {
                      const postExcerpt = smartTrim(
                        p?.description.replace(
                          /<img[^>"']*((("[^"]*")|('[^']*'))[^"'>]*)*>/g,
                          ''
                        ),
                        150,
                        ' ',
                        ' ...'
                      );
                      return (
                        <div className="col-span-3" key={i}>
                          <article className="flex flex-col shadow my-4">
                            <NavLink
                              to={`/post/${p?.slug}`}
                              className="hover:opacity-75"
                            >
                              <img
                                src={p?.images[0].url}
                                alt={p?.title}
                                className="w-full max-h-[35rem] h-[20rem]"
                              />
                            </NavLink>
                            <div className="bg-white flex flex-col justify-start p-6 min-h-[19rem]">
                              <NavLink
                                to={`/category/${
                                  !p?.category?.slug
                                    ? 'uncategorized'
                                    : p?.category?.slug
                                }`}
                                className="text-cyan-600 text-sm font-bold uppercase pb-4"
                              >
                                {!p?.category?.name
                                  ? 'Uncategorized'
                                  : p?.category?.name}
                              </NavLink>
                              <NavLink
                                to={`/post/${p?.slug}`}
                                className="text-2xl hover:text-gray-700 pb-4 h-16"
                              >
                                {p?.title}
                              </NavLink>
                              <p className="text-sm pb-3 flex flex-row">
                                <NavLink to={`/${p?.postedBy?.username}`}>
                                  <img
                                    src={p?.postedBy?.profilePic}
                                    alt="Profile Pic"
                                    className="rounded-full w-6 h-6 mr-2"
                                  />
                                </NavLink>
                                <NavLink
                                  to={`/${p?.postedBy?.username}`}
                                  className="font-semibold hover:text-gray-800"
                                >
                                  {p?.postedBy.firstName}
                                </NavLink>
                                , Published on: {moment(p?.createdAt).fromNow()}
                              </p>
                              <NavLink
                                to={`/post/${p?.slug}`}
                                className="pb-6 h-20"
                              >
                                {parse(`
                ${postExcerpt}
              `)}
                              </NavLink>
                              <NavLink
                                to={`/post/${p?.slug}`}
                                className="md:w-3/5 sm:w-full text-center uppercase bg-cyan-600 text-white font-bold text-sm rounded hover:bg-cyan-800 hover:text-white px-5 py-3 mt-4"
                              >
                                Continue Reading
                              </NavLink>
                            </div>
                          </article>
                        </div>
                      );
                    })}
                  </div>

                  <div className="my-5 w-full h-2/3">
                    <h1 className="text-2xl text-left text-gray-700">
                      Popular posts
                    </h1>
                    <div className="flex justify-center items-center">
                      <Swiper
                        loop={true}
                        autoplay={{
                          delay: 5000,
                        }}
                        modules={[Navigation]}
                        navigation
                        style={{
                          '--swiper-navigation-color': '#1890ff',
                          '--swiper-navigation-size': '30px',
                        }}
                        slidesPerView={1}
                        spaceBetween={30}
                        className="blogSwiper"
                        breakpoints={{
                          640: {
                            slidesPerView: 2,
                            spaceBetween: 20,
                          },
                          768: {
                            slidesPerView: 2,
                            spaceBetween: 20,
                          },
                          1024: {
                            slidesPerView: 2,
                            spaceBetween: 30,
                          },
                        }}
                      >
                        {posts
                          .sort((a, b) => (a.numViews < b.numViews ? 1 : -1))
                          .map((p, i) => {
                            const postSlide2Excerpt = smartTrim(
                              p?.description.replace(
                                /<img[^>"']*((("[^"]*")|('[^']*'))[^"'>]*)*>/g,
                                ''
                              ),
                              80,
                              ' ',
                              ' ...'
                            );
                            return (
                              <SwiperSlide key={i}>
                                <div className="col-span-3">
                                  <article className="shadow my-4">
                                    <NavLink
                                      to={`/post/${p?.slug}`}
                                      className="hover:opacity-75"
                                    >
                                      <img
                                        src={p?.images[0].url}
                                        alt={p?.title}
                                        className="w-full max-h-[35rem] md:h-[20rem] sm:h-[15rem]"
                                      />
                                    </NavLink>
                                    <div className="bg-white flex flex-col justify-start p-6 h-[14rem]">
                                      <NavLink
                                        to={`/category/${
                                          !p?.category?.slug
                                            ? 'uncategorized'
                                            : p?.category?.slug
                                        }`}
                                        className="text-cyan-600 text-sm font-bold uppercase pb-4"
                                      >
                                        {!p?.category?.name
                                          ? 'Uncategorized'
                                          : p?.category?.name}
                                      </NavLink>
                                      <NavLink
                                        to={`/post/${p?.slug}`}
                                        className="text-lg hover:text-gray-700 pb-4"
                                      >
                                        {p?.title}
                                      </NavLink>

                                      <NavLink
                                        to={`/post/${p?.slug}`}
                                        className="pb-6"
                                      >
                                        {parse(`
                ${postSlide2Excerpt}
              `)}
                                      </NavLink>
                                    </div>
                                  </article>
                                </div>
                              </SwiperSlide>
                            );
                          })}
                      </Swiper>
                    </div>
                  </div>
                </section>
              </>
            )}

            {/* Sidebar */}
            <WebSideBar adverts={adverts} />
          </div>
        </>
      )}
    </>
  );
};

export default Home;
