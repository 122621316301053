import { useState, useEffect } from 'react';
import { io } from 'socket.io-client';
import NotificationPortal from './NotificationPortal';
import UserLikedNotification from './UserLikedNotification';
import UserPostDeletedPortal from './UserPostDeletedPortal';

function SocketHoc({ user, socket, setConnectedUsers, children }) {
  const [newNotification, setNewNotification] = useState(null);
  const [notificationPopup, showNotificationPopup] = useState(false);

  const [userPostDeleted, setUserPostDeleted] = useState(null);
  const [userPostDeletedPopup, showuserPostDeletedPopup] = useState(false);

  const [userPostLiked, setUserPostLiked] = useState(null);
  const [userPostLikedPopup, showUserPostLikedPopup] = useState(false);

  useEffect(() => {
    if (!socket.current) {
      socket.current = io('https://backend.coinanalysis.org');
    }

    if (socket.current) {
      socket.current.emit('join', { userId: user?.id });

      socket.current.on('connectedUsers', ({ users }) => {
        users.length > 0 && setConnectedUsers(users);
      });

      if (user?.role === 'admin') {
        socket.current.on('adminNotification', ({ firstName, profilePic }) => {
          setNewNotification({ firstName, profilePic });
          showNotificationPopup(true);
          document.title = 'New post created';
        });
      }

      socket.current.on('userPostDeletedNotification', ({ title }) => {
        setUserPostDeleted({ title });
        showuserPostDeletedPopup(true);

        document.title = 'Your Post has been deleted.';
      });

      socket.current.on(
        'newNotificationReceived',
        ({ firstName, profilePic, postId, postSlug }) => {
          setUserPostLiked({ firstName, profilePic, postId, postSlug });
          showUserPostLikedPopup(true);

          document.title = 'Liked your post';
        }
      );

      return () => {
        if (socket.current) {
          socket.current.emit('disconnect');
          socket.current.off();
        }
      };
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {notificationPopup && newNotification !== null && (
        <NotificationPortal
          newNotification={newNotification}
          notificationPopup={notificationPopup}
          showNotificationPopup={showNotificationPopup}
        />
      )}

      {userPostDeletedPopup && userPostDeleted !== null && (
        <UserPostDeletedPortal
          userPostDeleted={userPostDeleted}
          userPostDeletedPopup={userPostDeletedPopup}
          showuserPostDeletedPopup={showuserPostDeletedPopup}
        />
      )}

      {userPostLikedPopup && userPostLiked !== null && (
        <UserLikedNotification
          userPostLiked={userPostLiked}
          userPostLikedPopup={userPostLikedPopup}
          showUserPostLikedPopup={showUserPostLikedPopup}
        />
      )}

      {children}
    </>
  );
}

export default SocketHoc;
