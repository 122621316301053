import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import './App.css';

import Header from './components/nav/Header';
import AdminRoute from './components/routes/AdminRoute';
import NotLoggedInRoute from './components/routes/NotLoggedInRoute';
import UserRoute from './components/routes/UserRoute';
import { currentUser } from './functions/users';
import AdminDashboard from './pages/admin/AdminDashboard';
import AdminCategory from './pages/admin/category/AdminCategory';
import AdminCategoryEdit from './pages/admin/category/AdminCategoryEdit';
import AdminCreateNewPost from './pages/admin/post/AdminCreateNewPost';
import AdminPost from './pages/admin/post/AdminPost';
import AdminPostEdit from './pages/admin/post/AdminPostEdit';
import AdminSubCategory from './pages/admin/subcategory/AdminSubCategory';
import AdminSubCategoryEdit from './pages/admin/subcategory/AdminSubCategoryEdit';
import AdminUser from './pages/admin/users/AdminUser';
import ForgotPassword from './pages/auth/ForgotPassword';
import Login from './pages/auth/Login';
import Register from './pages/auth/Register';
import NotFound from './pages/NotFound/NotFound';
import UserDashboard from './pages/user/UserDashboard';
import Cookies from 'js-cookie';
import io from 'socket.io-client';
import AdminCreateUser from './pages/admin/users/AdminCreateUser';
import AdminSettings from './pages/admin/settings/AdminSettings';
import UserSettings from './pages/user/settings/UserSettings';
import UserPost from './pages/user/post/UserPost';
import UserPostEdit from './pages/user/post/UserPostEdit';
import UserCreateNewPost from './pages/user/post/UserCreateNewPost';
import SocketHoc from './components/notification/SocketHoc';
import Home from './pages/Home/Home';
import PostDetails from './pages/Post/PostDetails';
import MainProfile from './pages/user/profile/MainProfile';
import Category from './pages/Category/Category';
import WebsiteSettings from './pages/admin/website/WebsiteSettings';
import { getWebsiteCategories } from './functions/category';
import { getSubCategories } from './functions/subcategory';
import SubCategory from './pages/Category/SubCategory';
import Blog from './pages/Blog/Blog';
import Footer from './components/footer/Footer';

const App = () => {
  const user = useSelector((state) => state.user);
  const [userData, setUserData] = useState();
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [connectedUsers, setConnectedUsers] = useState([]);
  const socket = useRef();

  const [webCategories, setWebCategories] = useState([]);
  const [adverts, setAdverts] = useState([]);
  const [subs, setSubs] = useState([]);
  const [footer, setFooter] = useState('');
  const [footerCopy, setFooterCopy] = useState('');

  const initWebsiteCategories = () => {
    getWebsiteCategories()
      .then((res) => {
        if (res.status === 'ok') {
          setWebCategories(res?.data[0]?.categories);
          setAdverts(res?.data[0]?.advertisments);
          setFooter(res?.data[0]?.footerText);
          setFooterCopy(res?.data[0]?.footerCopyRightText);
        }
      })
      .catch((err) => {
        console.log(err.response.data.message);
      });
  };

  const loadSubCategories = () => {
    getSubCategories()
      .then((res) => {
        if (res.status === 'ok') {
          setSubs(res?.data);
        }
      })
      .catch((err) => {
        console.log(err?.response?.data?.message);
      });
  };

  useEffect(() => {
    initWebsiteCategories();
    loadSubCategories();
  }, []);

  // useEffect(() => {
  //   if (!socket.current) {
  //     socket.current = io('http://localhost:8000')
  //   }

  //   if (socket.current) {
  //     socket.current.emit('join', { userId: user?.id })

  //     socket.current.on('connectedUsers', ({ users }) => {
  //       users.length > 0 && setConnectedUsers(users)
  //     })
  //   }

  //   return () => {
  //     if (socket.current) {
  //       socket.current.emit('disconnect')
  //       socket.current.off()
  //     }
  //   }
  // }, [])

  useEffect(() => {
    currentUser(user?.token)
      .then((res) => {
        if (res.status === 'ok') {
          setUserData(res?.data);
        }
      })
      .catch((err) => {
        // console.log(err)
      });
  }, [user?.token]);

  const logout = () => {
    Cookies.remove('user');
    dispatch({
      type: 'LOGOUT',
      payload: null,
    });
    navigation('/login');
  };

  useEffect(() => {
    if (userData?.user === true) {
      toast.error('Your account has been blocked.');
      setTimeout(() => {
        logout();
      }, 1500);
    }
  }, [userData?.user]);

  return (
    <>
      <div className="container mx-auto">
        <Header webCategories={webCategories} subs={subs} />
        <ToastContainer />
        <SocketHoc
          user={user}
          socket={socket}
          setConnectedUsers={setConnectedUsers}
        >
          <Routes>
            <Route exact path="/" element={<Home adverts={adverts} />} />
            <Route
              exact
              path="/category/:slug"
              element={<Category adverts={adverts} />}
            />
            <Route
              exact
              path="/sub-category/:slug"
              element={<SubCategory adverts={adverts} />}
            />
            <Route
              exact
              path="/post/:slug"
              element={<PostDetails socket={socket} adverts={adverts} />}
            />
            <Route
              exact
              path="/blog/posts"
              element={<Blog adverts={adverts} />}
            />
            <Route element={<NotLoggedInRoute />}>
              <Route exact path="/signup" element={<Register />} />
              <Route exact path="/login" element={<Login />} />
              <Route
                exact
                path="/forgot-password"
                element={<ForgotPassword />}
              />
            </Route>

            <Route element={<UserRoute />}>
              <Route exact path="/user/dashboard" element={<UserDashboard />} />
              <Route
                exact
                path="/user/post"
                element={<UserPost socket={socket} />}
              />
              <Route
                exact
                path="/user/post/new"
                element={<UserCreateNewPost socket={socket} />}
              />
              <Route exact path="/user/post/:slug" element={<UserPostEdit />} />
              <Route exact path="/user/settings" element={<UserSettings />} />
              <Route exact path="/:username" element={<MainProfile />} />
            </Route>
            <Route element={<AdminRoute />}>
              <Route
                exact
                path="/admin/dashboard"
                element={<AdminDashboard />}
              />
              <Route
                exact
                path="/admin/post"
                element={<AdminPost socket={socket} />}
              />
              <Route
                exact
                path="/admin/post/new"
                element={<AdminCreateNewPost />}
              />
              <Route
                exact
                path="/admin/post/:slug"
                element={<AdminPostEdit />}
              />
              <Route exact path="/admin/category" element={<AdminCategory />} />
              <Route
                exact
                path="/admin/category/:slug"
                element={<AdminCategoryEdit />}
              />
              <Route
                exact
                path="/admin/sub-category/:slug"
                element={<AdminSubCategoryEdit />}
              />
              <Route
                exact
                path="/admin/sub-category"
                element={<AdminSubCategory />}
              />
              <Route
                exact
                path="/admin/users"
                element={<AdminUser connectedusers={connectedUsers} />}
              />
              <Route
                exact
                path="/admin/users/create-user"
                element={<AdminCreateUser />}
              />
              <Route exact path="/admin/settings" element={<AdminSettings />} />
              <Route
                exact
                path="/admin/website"
                element={<WebsiteSettings />}
              />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </SocketHoc>
        <Footer footer={footer} footerCopy={footerCopy} />
      </div>
    </>
  );
};

export default App;
