import React, { useRef, useState } from 'react';
import { Select } from 'antd';
import { Editor } from '@tinymce/tinymce-react';
import FadeLoader from 'react-spinners/FadeLoader';

const { Option } = Select;

const cloudName = process.env.REACT_APP_CLOUDINARY_NAME;
const unsignedUploadPreset = process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET;
const tiny_api = process.env.REACT_APP_TINY_API_KEY;

const PostCreateForm = ({
  handleSubmit,
  handleChange,
  setValues,
  values,
  handleCatagoryChange,
  subOptions,
  showSub,
}) => {
  const { title, description, categories, category, subcategories, images } =
    values;
  const editorRef = useRef(null);

  const [loading, setLoading] = useState(false);

  const [state, setState] = useState({
    content: '',
    saved: false,
    post: {
      description: '',
    },
    urlImage: '',
    loading: false,
  });

  //   const _handleEditorChange = (e) => {
  //     // console.log('Content was updated:', h2m(e.target.getContent()))
  //     setState({ ...state, content: e.target.getContent() })
  //     setValues({ ...values, description: state.content })
  //   }

  const _handSave = () => {
    //Let push state.content which you got to server
    //can view result at console window :)
    console.log(state.content);
    setState({ ...state, saved: true });
  };

  React.useEffect(() => {
    const input = document.getElementsByTagName('input');
    if (state.loading && input) {
      input.disabled = true;
    } else if (!state.loading && input) {
      input.disabled = false;
    }
  });

  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="md-col-span-5">
        <label>Post Title</label>
        <input
          type="text"
          name="title"
          className="block w-full mb-4 px-4 py-2 mt-2 text-cyan-700 bg-white border rounded-md focus:border-cyan-700 focus:outline-none"
          value={title}
          onChange={handleChange}
        />
      </div>

      {loading && (
        <div className="loader_new">
          <FadeLoader color="#03a9f4" loading={loading} size={20} />
        </div>
      )}

      <div className="md-col-span-5">
        <label className="block py-2">Description</label>

        <Editor
          apiKey={tiny_api}
          initialValue=""
          placeholder="Ask a question or post an update"
          onInit={(evt, editor) => (editorRef.current = editor)}
          init={{
            height: 600,
            menubar: true,
            config: {},
            images_upload_base_path: `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`,
            images_upload_credentials: true,
            plugins: [
              'autolink',
              'lists',
              'link',
              'image',
              'charmap',
              'preview',
              'anchor',
              'searchreplace',
              'visualblocks',
              'code',
              'fullscreen',
              'insertdatetime',
              'media',
              'table',
              'code',
              'wordcount',
            ],
            toolbar:
              'undo redo | formatselect | ' +
              'bold italic backcolor | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | ' +
              'removeformat | image',
            image_title: true,
            automatic_uploads: true,
            image_dimensions: false,
            file_picker_types: 'image',
            tinydrive_max_image_dimension: 1024,
            content_style: 'img {max-width: 100%;}',
            file_picker_callback: function (cb, value, meta) {
              var input = document.createElement('input');
              input.setAttribute('type', 'file');
              input.setAttribute('accept', 'image/*');

              var url = `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`;
              var xhr = new XMLHttpRequest();
              var fd = new FormData();
              xhr.open('POST', url, true);

              input.onchange = function () {
                var file = this.files[0];
                var reader = new FileReader();
                setLoading(true);
                xhr.onload = function () {
                  if (xhr.readyState === 4 && xhr.status === 200) {
                    var response = JSON.parse(xhr.responseText);
                    var url = response.secure_url;
                    cb(url, { title: response.original_filename });
                    setLoading(false);
                  }
                };

                reader.onload = function () {
                  var id = 'blobid' + new Date().getTime();
                  var blobCache =
                    window.tinymce.activeEditor.editorUpload.blobCache;
                  var base64 = reader.result.split(',')[1];
                  var blobInfo = blobCache.create(id, file, base64);
                  blobCache.add(blobInfo);
                  fd.append('upload_preset', unsignedUploadPreset);
                  fd.append('tags', 'browser_upload');
                  fd.append('file', blobInfo.blob(), blobInfo.filename());

                  xhr.send(fd);
                };

                reader.readAsDataURL(file);
              };

              input.click();
            },
          }}
          onChange={(e) =>
            setValues({ ...values, description: e.target.getContent() })
          }
        />
      </div>

      <br />

      <div className="md-col-span-5">
        <label>Category</label>
        <select
          name="category"
          className="block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
          onChange={handleCatagoryChange}
        >
          <option>Please select</option>
          {categories.length > 0 &&
            categories.map((c) => (
              <option key={c._id} value={c._id}>
                {c.name}
              </option>
            ))}
        </select>
      </div>

      <br />

      {showSub && (
        <div className="md-col-span-5">
          <label className="block py-2">Sub Categories</label>
          <Select
            mode="multiple"
            style={{ width: '100%' }}
            placeholder="Please select"
            value={subcategories}
            onChange={(value) => setValues({ ...values, subcategories: value })}
          >
            {subOptions.length &&
              subOptions.map((s) => (
                <Option key={s._id} value={s._id}>
                  {s.name}
                </Option>
              ))}
          </Select>
        </div>
      )}
      <br />

      <div className="d-grid gap-2 my-2 w-36">
        <button
          type="submit"
          className={`w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform ${
            loading
              ? 'bg-gray-500 hover:bg-gray-500'
              : 'bg-cyan-500 hover:bg-cyan-500'
          }  rounded-md  focus:outline-none focus:bg-cyan-600`}
          disabled={loading}
        >
          Save
        </button>
      </div>
      <br />
      <br />
    </form>
  );
};

export default PostCreateForm;
