import React from 'react'
import { useLocation } from 'react-router-dom'

const LocalSearch = ({ keyword, setKeyword }) => {
  const location = useLocation()
  const handleSearchChange = (e) => {
    e.preventDefault()
    setKeyword(e.target.value.toLowerCase())
  }

  return (
    <input
      type="search"
      placeholder={
        location.pathname === '/admin/category'
          ? 'Filter category'
          : location.pathname === '/admin/post' ||
            location.pathname === '/user/post'
          ? 'Filter post'
          : location.pathname === '/admin/users'
          ? 'Filter user'
          : 'Filter sub category'
      }
      value={keyword}
      onChange={handleSearchChange}
      className="block w-full mb-4 px-4 py-2 mt-2 text-cyan-700 bg-white border rounded-md focus:border-cyan-700 focus:outline-none"
    />
  )
}

export default LocalSearch
