import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { useStateContext } from '../../contexts/ContextProvider'
import { currentUser, getAllUsers } from '../../functions/users'
import Cookies from 'js-cookie'
import { MdOutlineSupervisorAccount } from 'react-icons/md'
import { LinkOutlined } from '@ant-design/icons'
import { BsListCheck, BsListNested, BsFileEarmarkPost } from 'react-icons/bs'
import { toast } from 'react-toastify'
import AdminSidebar from '../../components/sidebar/AdminSidebar'
import Navbar from '../../components/nav/Navbar'
import { getCategories } from '../../functions/category'
import { getSubCategories } from '../../functions/subcategory'
import { getAllPost } from '../../functions/post'

const AdminDashboard = () => {
  const user = useSelector((state) => state.user)
  const [userData, setUserData] = useState()
  const [users, setUsers] = useState([])
  const [error, setError] = useState(null)
  const [categories, setCategories] = useState([])
  const [subCategories, setSubCategories] = useState([])
  const [loading, setLoading] = useState(false)
  const [post, setPost] = useState([])
  const dispatch = useDispatch()
  const navigation = useNavigate()
  const { activeMenu } = useStateContext()

  useEffect(() => {
    currentUser(user?.token)
      .then((res) => {
        if (res.status === 'ok') {
          setUserData(res?.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  const logout = () => {
    Cookies.remove('user')
    dispatch({
      type: 'LOGOUT',
      payload: null,
    })
    navigation('/login')
  }

  useEffect(() => {
    if (userData?.data === true) {
      toast.error('Your account has been blocked.')
      setTimeout(() => {
        logout()
      }, 1500)
    }
  }, [userData?.data])

  const loadCategories = () => {
    setLoading(true)
    getCategories()
      .then((res) => {
        if (res.status === 'ok') {
          setCategories(res.data)
          setLoading(false)
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message)
        setLoading(false)
      })
  }

  const loadSubCategories = () => {
    setLoading(true)
    getSubCategories()
      .then((res) => {
        if (res.status === 'ok') {
          setSubCategories(res.data)
          setLoading(false)
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message)
        setLoading(false)
      })
  }

  const loadAllPosts = async () => {
    setLoading(true)
    try {
      setLoading(true)

      const res = await getAllPost(setError)

      if (res && res.status === 200) {
        setPost(res?.data?.posts)
        setError('')
      }

      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const loadAllUsers = () => {
    setLoading(true)
    getAllUsers(user.token)
      .then((res) => {
        if (res.status === 'ok') {
          setUsers(res.data)
          setLoading(false)
        }
      })
      .catch((err) => {
        setLoading(false)
        toast.error(err.response.data.message)
      })
  }

  useEffect(() => {
    loadAllUsers()
    loadCategories()
    loadSubCategories()
    loadAllPosts()
  }, [])

  return (
    <div className="flex relative">
      {activeMenu ? (
        <div className="w-72 sidebar dark:bg-secondary-dark-bg bg-white ">
          <AdminSidebar />
        </div>
      ) : (
        <div className="w-0 dark:bg-secondary-dark-bg">
          <AdminSidebar />
        </div>
      )}
      <div
        className={
          activeMenu
            ? 'dark:bg-main-dark-bg  bg-main-bg min-h-screen w-full  '
            : 'bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 '
        }
      >
        <div className="md:static bg-main-bg dark:bg-main-dark-bg navbar w-full ">
          <Navbar />
        </div>
        <div className="mt-10">
          <div className="flex flex-wrap lg:flex-nowrap justify-center ">
            <div className="flex m-3 flex-wrap justify-center gap-1 items-center">
              <div className="relative bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg w-56  p-4 pt-5 rounded-2xl ">
                <button
                  type="button"
                  style={{
                    color: '#03C9D7',
                    backgroundColor: '#E5FAFB',
                  }}
                  className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl"
                >
                  <MdOutlineSupervisorAccount size={30} />
                </button>

                <div className="text-gray-600 text-sm font-semibold my-3">
                  <p className="float-left">Users</p>
                  <p className="float-right">({users?.length})</p>
                </div>

                <div className="clear-both"></div>
                <div className="flex space-x-2 text-gray-400 text-sm my-4">
                  <LinkOutlined
                    style={{
                      fontSize: '20px',
                    }}
                  />
                  <NavLink to={`/admin/users`}> Users</NavLink>
                </div>
              </div>
            </div>

            <div className="flex m-3 flex-wrap justify-center gap-1 items-center">
              <div className="relative bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg w-56  p-4 pt-5 rounded-2xl ">
                <button
                  type="button"
                  style={{
                    color: '#03C9D7',
                    backgroundColor: '#E5FAFB',
                  }}
                  className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl"
                >
                  <BsListCheck size={30} />
                </button>

                <div className="text-gray-600 text-sm font-semibold my-3">
                  <p className="float-left">Categories</p>
                  <p className="float-right">({categories?.length})</p>
                </div>

                <div className="clear-both"></div>
                <div className="flex space-x-2 text-gray-400 text-sm my-4">
                  <LinkOutlined
                    style={{
                      fontSize: '20px',
                    }}
                  />
                  <NavLink to={`/admin/category`}> Categories</NavLink>
                </div>
              </div>
            </div>

            <div className="flex m-3 flex-wrap justify-center gap-1 items-center">
              <div className="relative bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg w-56  p-4 pt-5 rounded-2xl ">
                <button
                  type="button"
                  style={{
                    color: '#03C9D7',
                    backgroundColor: '#E5FAFB',
                  }}
                  className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl"
                >
                  <BsListNested size={30} />
                </button>

                <div className="text-gray-600 text-sm font-semibold my-3">
                  <p className="float-left">Sub Categories</p>
                  <p className="float-right">({subCategories?.length})</p>
                </div>

                <div className="clear-both"></div>
                <div className="flex space-x-2 text-gray-400 text-sm my-4">
                  <LinkOutlined
                    style={{
                      fontSize: '20px',
                    }}
                  />
                  <NavLink to={`/admin/sub-category`}>Sub Categories</NavLink>
                </div>
              </div>
            </div>

            <div className="flex m-3 flex-wrap justify-center gap-1 items-center">
              <div className="relative bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg w-56  p-4 pt-5 rounded-2xl ">
                <button
                  type="button"
                  style={{
                    color: '#03C9D7',
                    backgroundColor: '#E5FAFB',
                  }}
                  className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl"
                >
                  <BsFileEarmarkPost size={30} />
                </button>

                <div className="text-gray-600 text-sm font-semibold my-3">
                  <p className="float-left">Posts</p>
                  <p className="float-right">({post?.length})</p>
                </div>

                <div className="clear-both"></div>
                <div className="flex space-x-2 text-gray-400 text-sm my-4">
                  <LinkOutlined
                    style={{
                      fontSize: '20px',
                    }}
                  />
                  <NavLink to={`/admin/post`}> Posts</NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminDashboard
