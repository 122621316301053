import React, { useEffect, useState } from 'react'
import { AiOutlineMenu } from 'react-icons/ai'
import { FiShoppingCart } from 'react-icons/fi'
import { BsChatLeft } from 'react-icons/bs'
import { RiNotification3Line } from 'react-icons/ri'
import { MdKeyboardArrowDown } from 'react-icons/md'
import { TooltipComponent } from '@syncfusion/ej2-react-popups'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import avatar from '../../data/avatar.jpg'
import { useStateContext } from '../../contexts/ContextProvider'
import Notification from './Notification'
import UserProfile from './UserProfile'
import {
  getAllNotifications,
  updateNotification,
} from '../../functions/notification'

const NavButton = ({ title, customFunc, icon, color, dotColor }) => (
  <TooltipComponent content={title} position="BottomCenter">
    <button
      type="button"
      onClick={() => customFunc()}
      style={{ color }}
      className="relative text-xl mr-6 rounded-full p-3 hover:bg-light-gray"
    >
      <span
        style={{ background: dotColor }}
        className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2"
      />

      {icon}
    </button>
  </TooltipComponent>
)

const Navbar = () => {
  const {
    activeMenu,
    setActiveMenu,
    handleClick,
    isClicked,
    setScreenSize,
    screenSize,
  } = useStateContext()

  const user = useSelector((state) => state.user)
  const [allNotification, setAllNotification] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth)

    window.addEventListener('resize', handleResize)

    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    if (screenSize <= 900) {
      setActiveMenu(false)
    } else {
      setActiveMenu(true)
    }
  }, [screenSize])

  const handleActiveMenu = () => setActiveMenu(!activeMenu)

  const loadAllNotification = () => {
    setLoading(true)
    getAllNotifications(user?.token)
      .then((res) => {
        if (res.status === 'ok') {
          setAllNotification(res.data)
          setLoading(false)
        }
      })
      .catch((err) => {
        setLoading(false)
        toast.error(err.response.data.message)
      })
  }

  useEffect(() => {
    loadAllNotification()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const unRead = allNotification?.notifications?.reduce(
    (a, c) => (c.readOrUnread === false ? ++a : a),
    0
  )

  const handleRead = (notiId, id) => {
    let data = {
      notificationId: id,
    }

    updateNotification(notiId, data, user?.token)
      .then((res) => {
        if (res?.data?.ok === true) {
          loadAllNotification()
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <div className="flex justify-between p-2 md:ml-6 md:mr-6 relative">
      <NavButton
        title="Menu"
        customFunc={handleActiveMenu}
        icon={<AiOutlineMenu />}
      />
      <div className="flex">
        <NavButton
          title="Notification"
          customFunc={() => handleClick('notification')}
          icon={<RiNotification3Line />}
        />

        {!isClicked?.notification && unRead > 0 && (
          <div className="bg-red-500 w-5 h-5 flex justify-center items-center rounded-full relative -inset-x-11">
            <span className="text-white text-[11px]">
              {unRead > 20 ? `${unRead}+` : unRead}
            </span>
          </div>
        )}

        <TooltipComponent content="Profile" position="BottomCenter">
          <div
            className="flex items-center gap-2 cursor-pointer p-1 hover:bg-light-gray rounded-lg"
            onClick={() => handleClick('userProfile')}
          >
            <img
              className="rounded-full w-8 h-8"
              src={user?.profilePic}
              alt="user-profile"
            />
            <p>
              <span className="text-gray-400 text-14">Hi,</span>{' '}
              <span className="text-gray-400 font-bold ml-1 text-14">
                {user?.firstName}
              </span>
            </p>
            <MdKeyboardArrowDown className="text-gray-400 text-14" />
          </div>
        </TooltipComponent>

        {isClicked.notification && (
          <Notification
            allNotification={allNotification}
            loading={loading}
            handleRead={handleRead}
          />
        )}
        {isClicked.userProfile && <UserProfile user={user} />}
      </div>
    </div>
  )
}

export default Navbar
