import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import { currentUser } from '../../functions/users'
import { BsFileEarmarkPost } from 'react-icons/bs'
import Cookies from 'js-cookie'
import { toast } from 'react-toastify'
import Navbar from '../../components/nav/Navbar'
import { useStateContext } from '../../contexts/ContextProvider'
import { LinkOutlined } from '@ant-design/icons'
import UserSidebar from '../../components/sidebar/UserSidebar'
import { getAllUserPost } from '../../functions/post'
import HeaderProfile from '../../components/HeaderProfile'

const UserDashboard = () => {
  const user = useSelector((state) => state.user)
  const [userData, setUserData] = useState()
  const [post, setPost] = useState([])
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const navigation = useNavigate()
  const { activeMenu } = useStateContext()

  const loadAllPosts = () => {
    setLoading(true)
    getAllUserPost(user?.token)
      .then((res) => {
        if (res.status === 'ok') {
          setPost(res.data)
          setLoading(false)
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message)
        setLoading(false)
      })
  }

  useEffect(() => {
    loadAllPosts()
  }, [])

  useEffect(() => {
    currentUser(user?.token)
      .then((res) => {
        if (res.status === 'ok') {
          setUserData(res?.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  const logout = () => {
    Cookies.remove('user')
    dispatch({
      type: 'LOGOUT',
      payload: null,
    })
    navigation('/login')
  }

  useEffect(() => {
    if (userData?.data === true) {
      toast.error('Your account has been blocked.')
      setTimeout(() => {
        logout()
      }, 1500)
    }
  }, [userData?.data])
  return (
    <div className="flex relative">
      {activeMenu ? (
        <div className="w-72 sidebar dark:bg-secondary-dark-bg bg-white ">
          <UserSidebar />
        </div>
      ) : (
        <div className="w-0 dark:bg-secondary-dark-bg">
          <UserSidebar />
        </div>
      )}
      <div
        className={
          activeMenu
            ? 'dark:bg-main-dark-bg  bg-main-bg min-h-screen w-full  '
            : 'bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 '
        }
      >
        <div className="md:static bg-main-bg dark:bg-main-dark-bg navbar w-full ">
          <Navbar />
        </div>
        <div>
          <div className="m-2 md:m-10 p-2 md:p-5 bg-white rounded-1xl">
            <HeaderProfile title={`Welcome  ${user?.firstName}`} />
            <div className="mt-10">
              <div className="flex flex-wrap lg:flex-nowrap justify-start ">
                <div className="flex m-3 flex-wrap justify-center gap-1 items-center">
                  <div className="relative bg-gray-100 h-44 dark:text-gray-200 dark:bg-secondary-dark-bg w-56  p-4 pt-5 rounded-2xl ">
                    <button
                      type="button"
                      style={{
                        color: '#03C9D7',
                        backgroundColor: '#E5FAFB',
                      }}
                      className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl"
                    >
                      <BsFileEarmarkPost size={30} />
                    </button>

                    <div className="text-gray-600 text-sm font-semibold my-3">
                      <p className="float-left">Posts</p>
                      <p className="float-right">({post?.length})</p>
                    </div>

                    <div className="clear-both"></div>
                    <div className="flex space-x-2 text-gray-400 text-sm my-4">
                      <LinkOutlined
                        style={{
                          fontSize: '20px',
                        }}
                      />
                      <NavLink to={`/user/post`}> Posts</NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserDashboard
