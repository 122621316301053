/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  EditOutlined,
  DeleteOutlined,
  CheckCircleFilled,
  ExclamationCircleOutlined,
  PauseCircleFilled,
} from '@ant-design/icons';
import FadeLoader from 'react-spinners/FadeLoader';

import LocalSearch from '../../../components/forms/LocalSearch';
import {
  getPostsByCount,
  removePost,
  updatePostStatus,
} from '../../../functions/post';
import { useSelector } from 'react-redux';
import { useStateContext } from '../../../contexts/ContextProvider';
import AdminSidebar from '../../../components/sidebar/AdminSidebar';
import Navbar from '../../../components/nav/Navbar';
import HeaderProfile from '../../../components/HeaderProfile';
import Pagination from '../../../components/pagination/pagination';

const AdminPost = ({ socket }) => {
  const [loading, setLoading] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [posts, setPosts] = useState([]);
  const user = useSelector((state) => state.user);
  const { activeMenu } = useStateContext();
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(20);

  const loadAllPosts = () => {
    setLoading(true);
    getPostsByCount()
      .then((res) => {
        if (res.status === 'ok') {
          setPosts(res.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response.data.message);
      });
  };

  useEffect(() => {
    setSuccess('');
    setError('');
    loadAllPosts();
  }, []);

  useEffect(() => {
    if (socket.current) {
      socket.current.on('adminNotification', ({ firstName, profilePic }) => {
        if (firstName) {
          loadAllPosts();
        }
      });
    }
  }, []);

  const searched = (keyword) => (p) => p.title.toLowerCase().includes(keyword);

  const handleRemove = (userId, title, slug) => {
    setSuccess('');
    setError('');
    if (window.confirm('Are you sure you want to delete?')) {
      if (socket.current) {
        socket.current.emit('postDeleted', {
          slug,
          userId,
        });

        socket.current.on('adminPostDeleted', ({ msg }) => {
          if (msg === 'postDeleted') {
            setInterval(() => {
              navigate('/admin/post');
              window.location.reload();
            }, 2000);
            setSuccess(`${title} has been deleted`);
          }
        });

        socket.current.on('userPostDeletedFailed', ({ error }) => {
          if (error) {
            setError(`${error}`);
          }
        });
      }

      // setLoading(true)
      // removePost(slug, user.token)
      //   .then((res) => {
      //     if (res.status === 'ok') {
      //       setLoading(false)
      //       loadAllPosts()
      //       toast.success(`"${title}" is deleted`)
      //       setInterval(() => {
      //         window.location.reload()
      //       }, 1000)
      //     } else {
      //       setLoading(false)
      //       toast.error(res)
      //     }
      //   })
      //   .catch((err) => {
      //     setLoading(false)
      //     toast.error(err.response.data.message)
      //   })
    }
  };

  const handleStatus = async (title, slug) => {
    if (window.confirm(`Are you sure you want to change status to ${title}?`)) {
      let data = {
        title,
      };
      updatePostStatus(data, slug, user.token)
        .then((res) => {
          if (res.status === 'ok') {
            setLoading(false);
            setInterval(() => {
              window.location.reload();
            }, 1000);
            toast.success(`Status is updated`);
          } else {
            setLoading(false);
            toast.error(res);
          }
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err.response.message);
        });
    }
  };

  //Get current data
  const indexOfLastPost = currentPage * dataPerPage;
  const indexOfFirstPost = indexOfLastPost - dataPerPage;
  const currentData = posts?.slice(indexOfFirstPost, indexOfLastPost);
  const howManyPages = Math.ceil(posts?.length / dataPerPage);

  return (
    <div className="flex relative">
      {activeMenu ? (
        <div className="w-72 sidebar dark:bg-secondary-dark-bg bg-white ">
          <AdminSidebar />
        </div>
      ) : (
        <div className="w-0 dark:bg-secondary-dark-bg">
          <AdminSidebar />
        </div>
      )}
      <div
        className={
          activeMenu
            ? 'dark:bg-main-dark-bg  bg-main-bg min-h-screen w-full  '
            : 'bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 '
        }
      >
        <div className="md:static bg-main-bg dark:bg-main-dark-bg navbar w-full">
          <Navbar />
        </div>
        <div className="m-2 md:m-10 mt-10 p-2 md:p-5 bg-white rounded-1xl">
          <HeaderProfile title={`Post (${posts?.length})`} />
          {loading && (
            <div className="flex justify-center items-center">
              <FadeLoader color="#06b6d4" loading={loading} size={20} />
            </div>
          )}
          {success && (
            <h5 className="text-center auth_error_success mt-3 text-success d-flex justify-content-center align-items-center">
              <CheckCircleFilled
                style={{
                  fontSize: '22px',
                  color: 'green',
                  marginRight: '5px',
                  position: 'relative',
                  top: '-3px',
                }}
              />
              {success}
            </h5>
          )}

          {error && (
            <h5 className="text-center auth_error_success my-3 text-danger d-flex justify-content-center align-items-center">
              {' '}
              <ExclamationCircleOutlined
                style={{
                  fontSize: '20px',
                  color: 'red',
                  marginRight: '5px',
                  position: 'relative',
                  top: '-3px',
                }}
              />{' '}
              {error}
            </h5>
          )}
          {!loading && (
            <>
              <div className="w-96">
                <NavLink
                  to="/admin/post/new"
                  className="bg-cyan-600 text-white p-2 rounded hover:text-neutral-50 px-4 py-2 tracking-wide transition-colors duration-200 transform"
                >
                  Add Post
                </NavLink>
              </div>
              <br />
              <LocalSearch keyword={keyword} setKeyword={setKeyword} />

              <div className="grid grid-cols-1 xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-5">
                {currentData?.filter(searched(keyword))?.map((p) => (
                  <div
                    className="rounded overflow-hidden shadow-lg"
                    key={p?._id}
                  >
                    <img
                      className="w-full"
                      src={p?.images[0]?.url}
                      alt="Post Image"
                      style={{ height: '245px' }}
                    />
                    <div className="px-6 py-4">
                      <div className="font-bold text-sm mb-2">
                        {p?.title.substring(0, 50).trimEnd() + '...'}
                      </div>
                    </div>
                    <div className="px-6 pt-4 pb-6 text-center">
                      <span className="mx-5">
                        {p?.published === 'published' ? (
                          <CheckCircleFilled
                            className="text-green-500"
                            onClick={() => handleStatus('Pending', p.slug)}
                            style={{
                              fontSize: '20px',
                            }}
                          />
                        ) : (
                          <PauseCircleFilled
                            className="text-orange-500"
                            onClick={() => handleStatus('Published', p.slug)}
                            style={{
                              fontSize: '20px',
                            }}
                          />
                        )}
                      </span>
                      <NavLink to={`/admin/post/${p?.slug}`}>
                        <span className="mx-5">
                          <EditOutlined
                            className="text-green-500"
                            style={{
                              fontSize: '20px',
                            }}
                          />
                        </span>
                      </NavLink>
                      <span
                        onClick={() =>
                          handleRemove(p?.postedBy, p?.title, p?.slug)
                        }
                        className="mx-5"
                      >
                        <DeleteOutlined
                          className="text-red-500"
                          style={{
                            fontSize: '20px',
                          }}
                        />
                      </span>
                    </div>
                  </div>
                ))}
              </div>

              <div className="my-6">
                <Pagination
                  pages={howManyPages}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminPost;
