import React from 'react'
import Resizer from 'react-image-file-resizer'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { Avatar, Badge } from 'antd'
import { CloudUploadOutlined } from '@ant-design/icons'
import { toast } from 'react-toastify'
import { useLocation } from 'react-router-dom'

const ProfileImageUpload = ({ values, setValues, setLoading }) => {
  const user = useSelector((state) => state.user)
  const location = useLocation()

  const noUplaod = () => {
    return null
  }

  const fileUploadAndResize = (e) => {
    let files = e.target.files
    let allUploadedFiles = values.profilePic

    if (files[0].size / 1024 > 5120) {
      return toast.error('File size should be less than 5MB')
    }

    if (files) {
      setLoading(true)
      for (let i = 0; i < files?.length; i++) {
        Resizer.imageFileResizer(
          files[i],
          720,
          720,
          'JPEG',
          100,
          0,
          (uri) => {
            axios
              .post(
                `${process.env.REACT_APP_BACKEND_URL}uploadpostimage`,
                { image: uri },
                {
                  headers: {
                    Authorization: user ? `Bearer ${user.token}` : '',
                  },
                }
              )
              .then((res) => {
                setLoading(false)
                allUploadedFiles = res.data.url

                setValues({ ...values, profilePic: allUploadedFiles })
              })
              .catch((err) => {
                setLoading(false)
                console.log('CLOUDINARY UPLOAD ERR', err)
              })
          },
          'base64'
        )
      }
    }
  }

  const handleImageRemove = (public_id) => {
    setLoading(true)
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}removepostimage`,
        { public_id },
        {
          headers: {
            Authorization: user ? `Bearer ${user.token}` : '',
          },
        }
      )
      .then((res) => {
        setLoading(false)
        const { images } = values
        let filteredImages = images.filter((item) => {
          return item.public_id !== public_id
        })
        setValues({ ...values, images: filteredImages })
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
      })
  }

  return (
    <>
      <div className="row">
        <Avatar
          src={values.profilePic}
          size={location.pathname === '/admin/new-post' ? 300 : 250}
          shape="square"
          className="mt-3"
          style={{ objectFit: 'contain' }}
        />
      </div>
      {values?.images?.length !== 1 && (
        <div className="row my-6">
          <label
            className={
              values?.images?.length === 1
                ? 'bg-gray-500 text-white rounded'
                : 'bg-cyan-600 text-white p-2 rounded'
            }
          >
            <CloudUploadOutlined
              style={{
                fontSize: '20px',
                marginRight: '5px',
                position: 'relative',
                top: '-3px',
              }}
            />{' '}
            Upload image
            <input
              type="file"
              hidden
              accept="images/*"
              onChange={
                values?.images?.length === 1 ? noUplaod : fileUploadAndResize
              }
              disabled={values?.images?.length === 1}
            />
          </label>
        </div>
      )}
    </>
  )
}

export default ProfileImageUpload
