import axios from 'axios'

export const getAllNotifications = async (authtoken) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}user/notifications`,
      {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
    return { status: 'ok', data }
  } catch (error) {
    return error.response.data.message
  }
}

export const updateNotification = async (mainId, subData, authtoken) => {
  try {
    const { data } = await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}user/notification-status/${mainId}`,
      { subData },
      {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
    return { status: 'ok', data }
  } catch (error) {
    return error.response.data.message
  }
}
