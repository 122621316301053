import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { ExclamationCircleOutlined } from '@ant-design/icons'

const NoRedirect = () => {
  const [count, setCount] = useState(3)
  let history = useNavigate()

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((currentCount) => --currentCount)
    }, 1000)
    // redirect once count is equal to 0
    count === 0 && history('/')
    // cleanup
    return () => clearInterval(interval)
  }, [count, history])

  return (
    <div className="container p-5 text-center">
      <p className="text-success h6 my-2 d-flex justify-content-center align-items-center">
        <ExclamationCircleOutlined
          style={{ fontSize: '20px', color: 'green', marginRight: '5px' }}
        />{' '}
        You are already loggedin{' '}
      </p>
      <p className="text-muted"> Redirecting you in {count} seconds</p>
    </div>
  )
}

export default NoRedirect
