import React from 'react'
import { Button } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import './style.css'

const CategoryForm = ({ handleSubmit, name, setName, loading }) => {
  const location = useLocation()
  return (
    <form onSubmit={handleSubmit} className="pr-5">
      <label>
        {' '}
        {location.pathname === '/admin/category'
          ? 'Category Name'
          : 'Sub Category Name'}{' '}
      </label>
      <input
        type="text"
        className="block w-full mb-4 px-4 py-2 mt-2 text-cyan-700 bg-white border rounded-md focus:border-cyan-700 focus:outline-none"
        onChange={(e) => setName(e.target.value)}
        value={name}
        autoFocus
        required
      />

      <div className="d-grid gap-2 my-2">
        <button
          type="submit"
          className={`w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform ${
            loading
              ? 'bg-gray-500 hover:bg-gray-500'
              : 'bg-cyan-500 hover:bg-cyan-500'
          }  rounded-md  focus:outline-none focus:bg-cyan-600`}
          disabled={loading}
        >
          Save
        </button>
      </div>
    </form>
  )
}
export default CategoryForm
