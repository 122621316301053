import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { Link, NavLink } from 'react-router-dom'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import FadeLoader from 'react-spinners/FadeLoader'
import CategoryForm from '../../../components/forms/CategoryForm'
import LocalSearch from '../../../components/forms/LocalSearch'
import { getCategories } from '../../../functions/category'
import {
  createSubCategory,
  getSubCategories,
  removeSubCategory,
} from '../../../functions/subcategory'
import { useStateContext } from '../../../contexts/ContextProvider'
import AdminSidebar from '../../../components/sidebar/AdminSidebar'
import Navbar from '../../../components/nav/Navbar'
import HeaderProfile from '../../../components/HeaderProfile'
const AdminSubCategory = () => {
  const user = useSelector((state) => state.user)
  const [name, setName] = useState('')
  const [keyword, setKeyword] = useState('')
  const [loading, setLoading] = useState(false)
  const [categories, setCategories] = useState([])
  const [category, setCategory] = useState('')
  const [subs, setSubs] = useState([])
  const { activeMenu } = useStateContext()

  useEffect(() => {
    loadCategories()
    loadSubCategories()
  }, [loading])

  const loadCategories = () => {
    getCategories()
      .then((res) => {
        if (res.status === 'ok') {
          setCategories(res.data)
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message)
      })
  }

  const loadSubCategories = () => {
    getSubCategories()
      .then((res) => {
        if (res.status === 'ok') {
          setSubs(res.data)
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message)
      })
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    if (!category.length) {
      return toast.error('Please select category')
    }

    setLoading(true)
    let data = {
      name,
      parent: category,
    }

    createSubCategory(data, user.token)
      .then((res) => {
        if (res.status === 'ok') {
          setLoading(false)
          toast.success(`"${name}" is created`)
          setName('')
          setCategories([])
          loadSubCategories()
          // window.location.reload()
        } else {
          setLoading(false)
          toast.error(res)
        }
      })
      .catch((err) => {
        setLoading(false)
        toast.error(err.response.data.message)
      })
  }

  const handleRemove = async (name, slug) => {
    if (window.confirm('Are you sure you want to delete?')) {
      setLoading(true)
      removeSubCategory(slug, user.token)
        .then((res) => {
          if (res.status === 'ok') {
            setLoading(false)
            loadSubCategories()
            toast.success(`"${name}" is deleted`)
          } else {
            setLoading(false)
            toast.error(res)
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message)
        })
    }
  }

  const searched = (keyword) => (c) => c.name.toLowerCase().includes(keyword)

  return (
    <div className="flex relative">
      {activeMenu ? (
        <div className="w-72 sidebar dark:bg-secondary-dark-bg bg-white ">
          <AdminSidebar />
        </div>
      ) : (
        <div className="w-0 dark:bg-secondary-dark-bg">
          <AdminSidebar />
        </div>
      )}
      <div
        className={
          activeMenu
            ? 'dark:bg-main-dark-bg  bg-main-bg min-h-screen w-full  '
            : 'bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 '
        }
      >
        <div className="md:static bg-main-bg dark:bg-main-dark-bg navbar w-full">
          <Navbar />
        </div>
        <div className="m-2 md:m-10 mt-10 p-2 md:p-5 bg-white rounded-1xl">
          <HeaderProfile title="Sub Category" />
          <div className="w-96">
            {loading && (
              <div className="flex justify-start items-center">
                <FadeLoader color="#06b6d4" loading={loading} size={20} />
              </div>
            )}
            <div className="md:col-span-5 mr-5">
              <label>Parent category</label>
              <select
                name="category"
                className="block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
                onChange={(e) => setCategory(e.target.value)}
                required
              >
                <option>Please select</option>
                {categories.length > 0 &&
                  categories.map((c) => (
                    <option key={c._id} value={c._id}>
                      {c.name}
                    </option>
                  ))}
              </select>
            </div>
            <br />

            <CategoryForm
              handleSubmit={handleSubmit}
              name={name}
              setName={setName}
              loading={loading}
            />
          </div>
          <br />
          <LocalSearch keyword={keyword} setKeyword={setKeyword} />

          <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
            <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
              <table className="min-w-full leading-normal">
                <tbody>
                  {subs?.filter(searched(keyword))?.map((s) => (
                    <tr key={s._id}>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm 3/5">
                        <div className="flex items-center">
                          <div className="ml-3">
                            <p className="text-gray-900 whitespace-no-wrap">
                              {s.name}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap text-center">
                          {s.slug !== 'uncategorized' && (
                            <>
                              <span
                                onClick={() => handleRemove(s.name, s.slug)}
                                className="btn btn-sm float-right mx-5"
                              >
                                <DeleteOutlined className="text-red-500" />
                              </span>
                              <Link
                                to={`/admin/sub-category/${s.slug}`}
                                as={NavLink}
                              >
                                <span className="btn btn-sm float-right">
                                  <EditOutlined className="text-green-500" />
                                </span>
                              </Link>
                            </>
                          )}
                        </p>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminSubCategory
