import React, { useState, useEffect } from 'react'
import { MdOutlineCancel } from 'react-icons/md'

import { chatData } from '../../data/dummy'
import { useStateContext } from '../../contexts/ContextProvider'
import Button from './Button'
import { getAllNotifications } from '../../functions/notification'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import LikeNotification from './Notify/LikeNotification'
import CommentNotification from './Notify/CommentNotification'
import FollowerNotification from './Notify/FollowerNotification'
import FadeLoader from 'react-spinners/FadeLoader'

const Notification = ({ allNotification, loading, handleRead }) => {
  const user = useSelector((state) => state.user)

  return (
    <div className="nav-item absolute right-5 md:right-40 top-16 bg-white dark:bg-[#42464D] p-8 rounded-lg w-96">
      <div className="flex justify-between items-center">
        <div className="flex gap-3">
          <p className="font-semibold text-lg dark:text-gray-200">
            Notifications
          </p>
        </div>
        <Button
          icon={<MdOutlineCancel />}
          color="rgb(153, 171, 180)"
          size="2xl"
          borderRadius="50%"
        />
      </div>
      <div className="mt-5 h-96 overflow-scroll">
        {loading && (
          <div className="flex justify-center items-center">
            <FadeLoader color="#06b6d4" loading={loading} size={20} />
          </div>
        )}
        {allNotification?.notifications?.length > 0 ? (
          <div>
            {allNotification?.notifications?.map((notify) => (
              <div
                key={notify?._id}
                className={`flex items-center leading-8 gap-5 border-b-1 border-color p-3 ${
                  notify?.readOrUnread === false ? 'bg-neutral-100' : 'bg-white'
                }`}
                onClick={() =>
                  !notify?.readOrUnread
                    ? handleRead(allNotification?._id, notify?._id)
                    : null
                }
              >
                {notify?.type === 'newLike' && notify?.post !== null && (
                  <LikeNotification notification={notify} />
                )}

                {notify?.type === 'newComment' && notify?.post !== null && (
                  <CommentNotification notification={notify} />
                )}

                {notify?.type === 'newFollower' && (
                  <FollowerNotification notification={notify} />
                )}
              </div>
            ))}
          </div>
        ) : (
          <div></div>
        )}

        {/* <div className="mt-5">
          <Button
            color="teal"
            text="See all notifications"
            borderRadius="10px"
            width="full"
          />
        </div> */}
      </div>
    </div>
  )
}

export default Notification
