import { useState } from 'react'
import './style.css'

const LoginFormInput = (props) => {
  const { label, errorMessage, onChange, id, ...inputProps } = props
  const [focused, setFocused] = useState(false)
  const handleFocus = (e) => {
    setFocused(true)
  }
  return (
    <div className="formInput">
      <div className="mb-4">
        {/* <label htmlFor={inputProps.id} className="form-label auth_label">
          {label}
        </label> */}
        <input
          className="block w-full px-4 py-2 mt-2 text-cyan-700 bg-white border rounded-md focus:border-cyan-700 focus:outline-none"
          {...inputProps}
          onChange={onChange}
          onBlur={handleFocus}
          focused={focused.toString()}
        />
        <span className="error_form">{errorMessage}</span>
      </div>
    </div>
  )
}

export default LoginFormInput
