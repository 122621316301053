import { useState } from 'react'
import './style.css'

const RegFormInput = (props) => {
  const { label, errorMessage, onChange, id, ...inputProps } = props
  const [focused, setFocused] = useState(false)
  const handleFocus = (e) => {
    setFocused(true)
  }

  return (
    <div className="formInput mb-4">
      {/* <label htmlFor={inputProps.id} className="form-label auth_label">
        {label}
      </label> */}
      <input
        className="block w-full px-4 py-2 mt-2 text-cyan-700 bg-white border rounded-md focus:border-cyan-700 focus:outline-none"
        {...inputProps}
        onChange={onChange}
        onBlur={handleFocus}
        onFocus={() => inputProps.name === 'cPassword' && setFocused(true)}
        focused={focused.toString()}
      />
      <span className="error_form">{errorMessage}</span>
    </div>
  )
}

export default RegFormInput
