import axios from 'axios'

export const getAllUsers = async (authtoken) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}users`,
      {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
    return { status: 'ok', data }
  } catch (error) {
    return error.response.data.message
  }
}

export const updateUserIsBlocked = async (newdata, authtoken) => {
  try {
    const { data } = await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}isblocked`,
      { newdata },
      {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
    return { status: 'ok', data }
  } catch (error) {
    return error.response.data.message
  }
}

export const currentUser = async (authtoken) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}current-user`,
      {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
    return { status: 'ok', data }
  } catch (error) {
    return error.response.data.message
  }
}

export const getCurrentProfile = async (authtoken, username) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}user/${username}`,
      {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
    return { status: 'ok', data }
  } catch (error) {
    return error.response.data.message
  }
}

export const getCurrentPostsProfile = async (authtoken, username) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}user/posts/${username}`,
      {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
    return { status: 'ok', data }
  } catch (error) {
    return error.response.data.message
  }
}

export const getUserFollowers = async (authtoken, userId) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}user/followers/${userId}`,
      {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
    return { status: 'ok', data }
  } catch (error) {
    return error.response.data.message
  }
}

export const getUserFollowing = async (authtoken, userId) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}user/following/${userId}`,
      {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
    return { status: 'ok', data }
  } catch (error) {
    return error.response.data.message
  }
}

export const followUser = async (
  authtoken,
  userToFollowId,
  setLoggedUserFollowStats
) => {
  try {
    await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}user/follow/${userToFollowId}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
    setLoggedUserFollowStats((prev) => ({
      ...prev,
      following: [...prev.following, { user: userToFollowId }],
    }))
  } catch (error) {
    return error.response.data.message
  }
}

export const unFollowUser = async (
  authtoken,
  userToUnFollowId,
  setLoggedUserFollowStats
) => {
  try {
    await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}user/unfollow/${userToUnFollowId}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )

    setLoggedUserFollowStats((prev) => ({
      ...prev,
      following: prev.following.filter(
        (following) => following.user !== userToUnFollowId
      ),
    }))
  } catch (error) {
    return error.response.data.message
  }
}

export const updateUserInfo = async (newdata, authtoken) => {
  try {
    const { data } = await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}user/userinfo`,
      { newdata },
      {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
    return { status: 'ok', data }
  } catch (error) {
    return error.response.data.message
  }
}

export const updateUserPassword = async (newdata, authtoken) => {
  try {
    const { data } = await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}user/user-password`,
      { newdata },
      {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
    return { status: 'ok', data }
  } catch (error) {
    return error.response.data.message
  }
}

export const updateUserGooglePassword = async (newdata, authtoken) => {
  try {
    const { data } = await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}user/user-google-password`,
      { newdata },
      {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
    return { status: 'ok', data }
  } catch (error) {
    return error.response.data.message
  }
}

export const updateAdminGooglePassword = async (newdata, authtoken) => {
  try {
    const { data } = await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}admin/google-password`,
      { newdata },
      {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
    return { status: 'ok', data }
  } catch (error) {
    return error.response.data.message
  }
}
