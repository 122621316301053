import React, { useState, useEffect } from 'react'
import { getAllPost } from '../../functions/post'
import WebSideBar from '../Sidebar/WebSideBar'
import { NavLink } from 'react-router-dom'
import { smartTrim } from '../../utils/helpers'
import moment from 'moment'
import Pagination from '../../components/pagination/pagination'
import FadeLoader from 'react-spinners/FadeLoader'

const Blog = ({ adverts }) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [postsPerPage] = useState(9)

  const loadData = async () => {
    try {
      setLoading(true)

      const res = await getAllPost(setError)

      if (res && res.status === 200) {
        setData(res?.data?.posts)
        setError('')
      }

      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  useEffect(() => {
    loadData()
  }, [])

  //Get current posts
  const indexOfLastPost = currentPage * postsPerPage
  const indexOfFirstPost = indexOfLastPost - postsPerPage
  const currentPosts = data?.slice(indexOfFirstPost, indexOfLastPost)
  const howManyPages = Math?.ceil(data?.length / postsPerPage)

  return (
    <div className="container mx-auto flex flex-wrap py-6">
      {currentPosts && currentPosts?.length > 0 && (
        <>
          {loading ? (
            <div className="relative w-full">
              <div className="flex justify-center items-center h-1 absolute w-full mb-6">
                {loading && (
                  <FadeLoader color="#06b6d4" loading={loading} size={20} />
                )}
              </div>
            </div>
          ) : (
            <section className="w-full md:w-2/3 flex flex-col items-center">
              <div className="grid grid-flow-row xs:grid-cols-12 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-6 gap-6">
                {currentPosts?.slice(0, 9)?.map((p, i) => {
                  const postExcerpt = smartTrim(
                    p?.description.replace(
                      /<img[^>"']*((("[^"]*")|('[^']*'))[^"'>]*)*>/g,
                      ''
                    ),
                    150,
                    ' ',
                    ' ...'
                  )
                  return (
                    <div className="col-span-3" key={i}>
                      <article className="flex flex-col shadow my-4">
                        <NavLink
                          to={`/post/${p?.slug}`}
                          className="hover:opacity-75"
                        >
                          <img
                            src={p?.images[0].url}
                            alt={p?.title}
                            className="w-full max-h-[35rem] h-[20rem]"
                          />
                        </NavLink>
                        <div className="bg-white flex flex-col justify-start p-6 min-h-[19rem]">
                          <NavLink
                            to={`/category/${
                              !p?.category?.slug
                                ? 'uncategorized'
                                : p?.category?.slug
                            }`}
                            className="text-cyan-600 text-sm font-bold uppercase pb-4"
                          >
                            {!p?.category?.name
                              ? 'Uncategorized'
                              : p?.category?.name}
                          </NavLink>
                          <NavLink
                            to={`/post/${p?.slug}`}
                            className="text-2xl hover:text-gray-700 pb-4 h-16"
                          >
                            {p?.title}
                          </NavLink>
                          <p className="text-sm pb-3 flex flex-row">
                            <NavLink to={`/${p?.postedBy?.username}`}>
                              <img
                                src={p?.postedBy?.profilePic}
                                alt="Profile Pic"
                                className="rounded-full w-6 h-6 mr-2"
                              />
                            </NavLink>
                            <NavLink
                              to={`/${p?.postedBy?.username}`}
                              className="font-semibold hover:text-gray-800"
                            >
                              {p?.postedBy.firstName}
                            </NavLink>
                            , Published on: {moment(p?.createdAt).fromNow()}
                          </p>
                          <NavLink
                            to={`/post/${p?.slug}`}
                            className="pb-6 h-20"
                          ></NavLink>
                          <NavLink
                            to={`/post/${p?.slug}`}
                            className="md:w-3/5 sm:w-full text-center uppercase bg-cyan-600 text-white font-bold text-sm rounded hover:bg-cyan-800 hover:text-white px-5 py-3 mt-4"
                          >
                            Continue Reading
                          </NavLink>
                        </div>
                      </article>
                    </div>
                  )
                })}
              </div>

              <div className="my-6">
                <Pagination
                  pages={howManyPages}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            </section>
          )}
        </>
      )}

      {loading ? (
        <div className="relative w-full">
          <div className="flex justify-center items-center h-1 absolute w-full mb-6">
            {loading && (
              <FadeLoader color="#06b6d4" loading={loading} size={20} />
            )}
          </div>
        </div>
      ) : (
        <WebSideBar adverts={adverts} />
      )}
    </div>
  )
}

export default Blog
