import axios from 'axios'

export const getCategories = async () => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}categories`
    )
    return { status: 'ok', data }
  } catch (error) {
    return error.response.data.message
  }
}

export const getWebsiteCategories = async () => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}websitecategories`
    )
    return { status: 'ok', data }
  } catch (error) {
    return error.response.data.message
  }
}

export const getCategory = async (slug) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}category/${slug}`
    )
    return { status: 'ok', data }
  } catch (error) {
    return error.response.data.message
  }
}

export const removeCategory = async (slug, authtoken) => {
  try {
    axios.delete(`${process.env.REACT_APP_BACKEND_URL}category/${slug}`, {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
    return { status: 'ok' }
  } catch (error) {
    return error.response.data.message
  }
}

export const updateCategory = async (slug, newdata, authtoken) => {
  try {
    const { data } = await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}category/${slug}`,
      { newdata },
      {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
    return { status: 'ok', data }
  } catch (error) {
    return error.response.data.message
  }
}

export const createCategory = async (newdata, authtoken) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}category`,
      { newdata },
      {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
    return { status: 'ok', data }
  } catch (error) {
    return error.response.data.message
  }
}

export const getCategorySubs = async (_id) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}category/subs/${_id}`
    )
    return { status: 'ok', data }
  } catch (error) {
    return error.response.data.message
  }
}
