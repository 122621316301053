import React from 'react'
import { NavLink } from 'react-router-dom'
import { useStateContext } from '../../../contexts/ContextProvider'
import { smartTrim } from '../../../utils/helpers'
import moment from 'moment'

const FollowerNotification = ({ notification }) => {
  const { setIsClicked, initialState } = useStateContext()
  return (
    <>
      <img
        className="rounded-full h-10 w-10"
        src={notification?.user?.profilePic}
        alt={notification?.user?.firstName}
      />
      <div>
        <p className="font-semibold text-sm dark:text-gray-200">
          <NavLink
            onClick={() => setIsClicked(initialState)}
            to={`/${notification?.user?.username}`}
          >
            {notification?.user?.firstName} {notification?.user?.lastName}
          </NavLink>
        </p>
        <p className="text-gray-500 text-sm dark:text-gray-400">
          Started following you.
        </p>

        <p className="text-gray-400 text-xs">
          {moment(notification?.date).fromNow()}
        </p>
      </div>
    </>
  )
}

export default FollowerNotification
