import React, { useState, useEffect } from 'react';

import {
  getPostsByCategory,
  getPostsBySubCategory,
  getPostsSlider,
} from '../../functions/post';
import parse from 'html-react-parser';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { smartTrim } from '../../utils/helpers';
import moment from 'moment';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Navigation } from 'swiper';
import 'swiper/css/navigation';
import 'swiper/css';
import { getCategory } from '../../functions/category';
import { toast } from 'react-toastify';
import WebSideBar from '../Sidebar/WebSideBar';
import { getSubCategory } from '../../functions/subcategory';
import FadeLoader from 'react-spinners/FadeLoader';
import Pagination from '../../components/pagination/pagination';

const SubCategory = ({ adverts }) => {
  const { slug } = useParams();
  const [posts, setPosts] = useState([]);
  const [name, setName] = useState({});
  const [loading, setLoading] = useState(false);
  const [errCat, setErrorCat] = useState(false);
  const navigation = useNavigate();

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(20);

  const loadCategories = async () => {
    try {
      setLoading(true);
      const response = await getSubCategory(slug);
      if (!response?.data?.subcategories) {
        setErrorCat(true);
      } else if (response.status === 'ok') {
        setName(response.data.subcategories);
      }
      setLoading(false);
    } catch (error) {
      toast.error(error.response.data?.message);
      setLoading(false);
    } finally {
      setLoading(false);
    }

    // getSubCategory(slug)
    //   .then((res) => {
    //     if (!res?.data?.subcategories) {
    //       return setErrorCat(true)
    //     }
    //     if (res.status === 'ok') {
    //       setName(res.data.subcategories)
    //     }
    //   })
    //   .catch((err) => {
    //     toast.error(err.response.data?.message)
    //   })
  };

  const loadPosts = async () => {
    try {
      setLoading(true);
      if (name?.slug === slug) {
        const response = await getPostsBySubCategory(name?._id);
        setPosts(response.data);
      }
      setLoading(false);
    } catch (error) {
      toast.error(error.response.data?.message);
      setLoading(false);
    } finally {
      setLoading(false);
    }

    // if (name?.slug === slug) {
    //   getPostsBySubCategory(name?._id)
    //     .then((res) => {
    //       setPosts(res.data)
    //     })
    //     .catch((err) => {
    //       toast.error(err.response.data?.message)
    //     })
    // }
  };

  useEffect(() => {
    loadCategories();
    loadPosts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug, name?.slug]);

  useEffect(() => {
    if (errCat) {
      navigation('*');
    }
    // eslint-disable-next-line
  }, [errCat]);

  //Get current data
  const indexOfLastPost = currentPage * dataPerPage;
  const indexOfFirstPost = indexOfLastPost - dataPerPage;
  const currentData = posts?.slice(indexOfFirstPost, indexOfLastPost);
  const howManyPages = Math.ceil(posts?.length / dataPerPage);

  return (
    <>
      <div className="container mx-auto flex flex-wrap py-6">
        <section className="w-full md:w-2/3 flex flex-col">
          <>
            <nav className="flex" aria-label="Breadcrumb">
              <ol className="inline-flex items-center space-x-1 md:space-x-3">
                <li className="inline-flex items-center">
                  <NavLink
                    to="/"
                    className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white"
                  >
                    <svg
                      aria-hidden="true"
                      className="w-4 h-4 mr-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
                    </svg>
                    Home
                  </NavLink>
                </li>
                <li>
                  <div className="flex items-center">
                    <svg
                      aria-hidden="true"
                      className="w-6 h-6 text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <div className="ml-1 text-sm font-medium text-gray-700 md:ml-2 dark:text-gray-400 dark:hover:text-white">
                      Category
                    </div>
                  </div>
                </li>
                <li aria-current="page">
                  <div className="flex items-center">
                    <svg
                      aria-hidden="true"
                      className="w-6 h-6 text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span className="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">
                      {name?.parent?.name}
                    </span>
                  </div>
                </li>
                <li aria-current="page">
                  <div className="flex items-center">
                    <svg
                      aria-hidden="true"
                      className="w-6 h-6 text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span className="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">
                      {name?.name}
                    </span>
                  </div>
                </li>
              </ol>
            </nav>

            {!loading && posts && posts?.length === 0 ? (
              <div className="text-center bg-stone-200 mt-3 p-3">
                <p className="text-lg font-normal text-red-500 lg:text-xl sm:px-16 xl:px-48 dark:text-gray-400">
                  {loading ? (
                    <div className="flex justify-start items-center">
                      <FadeLoader color="#06b6d4" loading={loading} size={20} />
                    </div>
                  ) : (
                    <span>
                      {' '}
                      We didn't find any posts in "{name?.name}" sub-category"{' '}
                    </span>
                  )}
                </p>
              </div>
            ) : (
              <div className="text-center bg-stone-200 mt-3 p-3">
                <p className="text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48 dark:text-gray-400">
                  ({posts?.length}) Posts in "{name?.name}" sub-category
                </p>
              </div>
            )}

            {!loading && posts && posts.length > 0 && (
              <div className="grid grid-flow-row xs:grid-cols-12 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-6 gap-6">
                {currentData?.map((p, i) => {
                  const postExcerpt = smartTrim(
                    p?.description.replace(
                      /<img[^>"']*((("[^"]*")|('[^']*'))[^"'>]*)*>/g,
                      ''
                    ),
                    120,
                    ' ',
                    ' ...'
                  );

                  return (
                    <div className="col-span-3" key={i}>
                      <article className="flex flex-col shadow my-4">
                        <NavLink
                          to={`/post/${p?.slug}`}
                          className="hover:opacity-75"
                        >
                          <img
                            src={p?.images[0].url}
                            alt={p?.title}
                            className="w-full max-h-[35rem] h-[20rem]"
                          />
                        </NavLink>
                        <div className="bg-white flex flex-col justify-start p-6 min-h-[19rem]">
                          <NavLink
                            to={`/category/${p?.category.slug}`}
                            className="text-cyan-600 text-sm font-bold uppercase pb-4"
                          >
                            {p?.category.name}
                          </NavLink>
                          <NavLink
                            to={`/post/${p?.slug}`}
                            className="text-2xl hover:text-gray-700 pb-4"
                          >
                            {p?.title}
                          </NavLink>
                          <p className="text-sm pb-3 flex flex-row">
                            <NavLink to={`/${p?.postedBy?.username}`}>
                              <img
                                src={p?.postedBy?.profilePic}
                                alt="Profile Pic"
                                className="rounded-full w-6 h-6 mr-2"
                              />
                            </NavLink>
                            <NavLink
                              to={`/${p?.postedBy?.username}`}
                              className="font-semibold hover:text-gray-800"
                            >
                              {p?.postedBy.firstName}
                            </NavLink>
                            , Published on: {moment(p?.createdAt).fromNow()}
                          </p>
                          <NavLink to={`/post/${p?.slug}`} className="pb-6">
                            {parse(`
                ${postExcerpt}
              `)}
                          </NavLink>
                          <NavLink
                            to={`/post/${p?.slug}`}
                            className="md:w-3/5 sm:w-full text-center uppercase bg-cyan-600 text-white font-bold text-sm rounded hover:bg-cyan-800 hover:text-white px-5 py-3 mt-4"
                          >
                            Continue Reading
                          </NavLink>
                        </div>
                      </article>
                    </div>
                  );
                })}
              </div>
            )}

            {posts?.length > 0 && (
              <div className="my-6">
                <Pagination
                  pages={howManyPages}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            )}
          </>
        </section>

        {/* Sidebar */}
        <WebSideBar adverts={adverts} />
      </div>
    </>
  );
};

export default SubCategory;
