import axios from 'axios';

export const getSubCategories = async () => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}sub-categories`
    );
    return { status: 'ok', data };
  } catch (error) {
    return error.response.data.message;
  }
};

export const getSubCategory = async (slug) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}sub-category/${slug}`
    );
    return { status: 'ok', data };
  } catch (error) {
    return error.response.data.message;
  }
};

export const removeSubCategory = async (slug, authtoken) => {
  try {
    axios.delete(`${process.env.REACT_APP_BACKEND_URL}sub-category/${slug}`, {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    });
    return { status: 'ok' };
  } catch (error) {
    return error.response.data.message;
  }
};

export const updateSubCategory = async (slug, newdata, authtoken) => {
  try {
    const { data } = await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}sub-category/${slug}`,
      { newdata },
      {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    );
    return { status: 'ok', data };
  } catch (error) {
    return error.response.data.message;
  }
};

export const updateWebsiteCategory = async (id, newdata, authtoken) => {
  try {
    const { data } = await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}websitecategoriesupdate/${id}`,
      { categories: newdata?.categories },
      {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    );
    return { status: 'ok', data };
  } catch (error) {
    return error.response.data.message;
  }
};

export const createAdvertisment = async (newdata, id, authtoken) => {
  try {
    const { data } = await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}advertismentcreate/${id}`,
      { newdata },
      {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    );
    return { status: 'ok', data };
  } catch (error) {
    return error.response.data.message;
  }
};

export const updateAdvertisment = async (newdata, id, authtoken) => {
  try {
    const { data } = await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}advertismentupdate/${id}`,
      { newdata },
      {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    );
    return { status: 'ok', data };
  } catch (error) {
    return error.response.data.message;
  }
};

export const updateWebsiteFooter = async (newdata, id, authtoken) => {
  try {
    const { data } = await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}advertismentfooterupdate/${id}`,
      { newdata },
      {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    );
    return { status: 'ok', data };
  } catch (error) {
    return error.response.data.message;
  }
};

export const updateWebsiteCopyText = async (newdata, id, authtoken) => {
  try {
    const { data } = await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}adminfootercopyright/${id}`,
      { newdata },
      {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    );
    return { status: 'ok', data };
  } catch (error) {
    return error.response.data.message;
  }
};

export const createSubCategory = async (newdata, authtoken) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}sub-category`,
      { newdata },
      {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    );
    return { status: 'ok', data };
  } catch (error) {
    return error.response.data.message;
  }
};
