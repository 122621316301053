import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import FadeLoader from 'react-spinners/FadeLoader';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  CheckCircleFilled,
  ExclamationCircleOutlined,
  EditOutlined,
  PauseCircleFilled,
} from '@ant-design/icons';
import { updateUserInfo, updateUserPassword } from '../../../functions/users';
import ProfileImageUpload from '../../../components/forms/ProfileImageUpload';
import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';
import moment from 'moment';
import { useStateContext } from '../../../contexts/ContextProvider';
import AdminSidebar from '../../../components/sidebar/AdminSidebar';
import Navbar from '../../../components/nav/Navbar';
import HeaderProfile from '../../../components/HeaderProfile';
import {
  createCategory,
  getCategories,
  getWebsiteCategories,
} from '../../../functions/category';
import {
  createAdvertisment,
  updateAdvertisment,
  updateWebsiteCategory,
  updateWebsiteCopyText,
  updateWebsiteFooter,
} from '../../../functions/subcategory';
import ImageUpload from '../../../components/forms/ImageUpload';
import AdsForm from '../../../components/forms/AdsForm';

const initialState = {
  title: '',
  btnText: '',
  btnLink: '',
  images: [],
};

const WebsiteSettings = () => {
  const user = useSelector((state) => state.user);
  const [openTab, setOpenTab] = useState(1);
  const [categories, setCategories] = useState([]);
  const [webCategories, setWebCategories] = useState({});
  const [checked, setChecked] = useState([]);
  const [adverts, setAdverts] = useState([]);

  const [loading, setLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [footerText, setFooterText] = useState('');
  const [footerCopyText, setFooterCopyText] = useState('');
  const dispatch = useDispatch();
  const { activeMenu } = useStateContext();

  const [values, setValues] = useState(initialState);

  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({});

  let decodedToken = jwt_decode(user?.token);
  const date1 = new Date(decodedToken.exp * 1000);
  const date2 = moment(new Date());

  const startDate = moment(date2, 'DD.MM.YYYY');
  const endDate = moment(date1, 'DD.MM.YYYY');

  const cookieDate = Number(endDate.diff(startDate, 'days'));

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const initCategories = () => {
    getCategories()
      .then((res) => {
        if (res && res.status === 'ok') {
          setCategories(res?.data);
        }
      })
      .catch((err) => {
        toast.error(err.response?.data.message);
      });
  };

  const initWebsiteCategories = () => {
    setLoading(true);
    getWebsiteCategories()
      .then((res) => {
        if (res && res.status === 'ok') {
          setCategoriesArray(res?.data[0]?.categories);
          setWebCategories(res?.data[0]);
          setAdverts(res?.data[0]?.advertisments);
          setFooterText(res?.data[0]?.footerText);
          setFooterCopyText(res?.data[0]?.footerCopyRightText);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response?.data.message);
      });
  };

  useEffect(() => {
    initCategories();
    initWebsiteCategories();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setCategoriesArray = (cat) => {
    let ca = [];
    cat.map((c, i) => {
      ca.push(c._id);
    });
    setChecked(ca);
  };

  const handleToggle = (c) => () => {
    const clickedCategory = checked.indexOf(c);
    const all = [...checked];

    if (clickedCategory === -1) {
      all.push(c);
    } else {
      all.splice(clickedCategory, 1);
    }
    setChecked(all);
  };

  const findOutCategory = (c) => {
    const result = checked.indexOf(c);
    if (result !== -1) {
      return true;
    } else {
      return false;
    }
  };

  const submitWebsiteCat = (e) => {
    e.preventDefault();
    if (checked.length < 3) {
      return toast.error('At least 3 categories need to be selected');
    }

    const data = {
      categories: checked,
    };
    setLoading(true);
    updateWebsiteCategory(webCategories?._id, data, user?.token)
      .then((res) => {
        if (res.status === 'ok') {
          setLoading(false);
          toast.success(`Menu Updated`);
          initCategories();
          initWebsiteCategories();
        } else {
          setLoading(false);
          toast.error(res);
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response?.data.message);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setError('');
    setSuccess('');

    const { title, btnText, btnLink, images } = values;

    if (!title || !btnText || !btnLink || !images.length) {
      return toast.error('All fields are required!');
    }

    let data = {
      title,
      btnText,
      btnLink,
      image: images[0]?.url,
    };
    setLoading(true);
    createAdvertisment(data, webCategories?._id, user.token)
      .then((res) => {
        if (res.status === 'ok') {
          console.log(res);
          setLoading(false);
          window.location.reload();
        } else {
          setLoading(false);
          toast.error(res);
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response?.data.message);
      });
  };

  const handleEdit = (id) => {
    setShowModal(true);
    const [getData] = adverts?.filter((ad) => ad._id === id);
    setModalData(getData);
    setValues({
      ...values,
      ...getData,
    });
  };

  const submitAdsUpdated = (e) => {
    e.preventDefault();

    setError('');
    setSuccess('');

    const { title, btnText, btnLink, images, image } = values;
    if (!title || !btnText || !btnLink) {
      return toast.error('All fields are required!');
    }

    let data = {
      title,
      btnText,
      btnLink,
      image: images.length === 0 ? image : images[0]?.url,
      adsId: modalData?._id,
    };

    setModalLoading(true);
    updateAdvertisment(data, webCategories?._id, user.token)
      .then((res) => {
        if (res && res.status === 'ok') {
          console.log(res);
          setModalLoading(false);
          setShowModal(false);
          window.location.reload();
          setValues({
            ...values,
            title: '',
            btnText: '',
            btnLink: '',
            images: [],
          });
        } else {
          setModalLoading(false);
          toast.error(res);
        }
      })
      .catch((err) => {
        setModalLoading(false);
        toast.error(err.response?.data.message);
      });
  };

  const onFooterChange = (e) => {
    setFooterText(e.target.value);
  };

  const onFooterCopyChange = (e) => {
    setFooterCopyText(e.target.value);
  };

  const submitFooter = (e) => {
    e.preventDefault();

    if (!footerText) {
      return toast.error('Field must not be empty');
    }

    let data = {
      text: footerText,
    };

    updateWebsiteFooter(data, webCategories?._id, user.token)
      .then((res) => {
        if (res && res.status === 'ok') {
          window.location.reload();
        } else {
          toast.error(res);
        }
      })
      .catch((err) => {
        toast.error(err.response?.data.message);
      });
  };

  const submitFooterCopy = (e) => {
    e.preventDefault();

    if (!footerCopyText) {
      return toast.error('Field must not be empty');
    }

    let data = {
      text: footerCopyText,
    };

    updateWebsiteCopyText(data, webCategories?._id, user.token)
      .then((res) => {
        if (res && res.status === 'ok') {
          window.location.reload();
        } else {
          toast.error(res);
        }
      })
      .catch((err) => {
        toast.error(err.response?.data.message);
      });
  };

  return (
    <div className="flex relative">
      {activeMenu ? (
        <div className="w-72 sidebar dark:bg-secondary-dark-bg bg-white ">
          <AdminSidebar />
        </div>
      ) : (
        <div className="w-0 dark:bg-secondary-dark-bg">
          <AdminSidebar />
        </div>
      )}
      <div
        className={
          activeMenu
            ? 'dark:bg-main-dark-bg  bg-main-bg min-h-screen w-full  '
            : 'bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 '
        }
      >
        <div className="md:static bg-main-bg dark:bg-main-dark-bg navbar w-full">
          <Navbar />
        </div>
        <div className="m-2 md:m-10 mt-10 p-2 md:p-5 bg-white rounded-1xl">
          <HeaderProfile title="Website Settings" />
          {loading && (
            <div className="flex justify-start items-center">
              <FadeLoader color="#06b6d4" loading={loading} size={20} />
            </div>
          )}
          <div className="w-full">
            {success && (
              <h5 className="text-left auth_error_success mt-3 text-success d-flex justify-content-center align-items-center">
                <CheckCircleFilled
                  style={{
                    fontSize: '22px',
                    color: 'green',
                    marginRight: '5px',
                    position: 'relative',
                    top: '-3px',
                  }}
                />
                {success}
              </h5>
            )}
            {error && (
              <h5 className="text-left auth_error_success my-3 text-danger d-flex justify-content-center align-items-center">
                {' '}
                <ExclamationCircleOutlined
                  style={{
                    fontSize: '20px',
                    color: 'red',
                    marginRight: '5px',
                    position: 'relative',
                    top: '-3px',
                  }}
                />{' '}
                {error}
              </h5>
            )}
          </div>

          <div className="flex flex-col items-start justify-start">
            <ul className="flex text-sm font-medium">
              <li>
                <div
                  onClick={() => setOpenTab(1)}
                  className={`-mb-px border-b-4 border-current pb-2 mr-3 ${
                    openTab === 1 ? 'text-cyan-500' : 'text-gray-500'
                  }  cursor-pointer`}
                >
                  Menu
                </div>
              </li>
              <li>
                <div
                  onClick={() => setOpenTab(2)}
                  className={`-mb-px border-b-4 border-current pb-2 mr-3 ${
                    openTab === 2 ? 'text-cyan-500' : 'text-gray-500'
                  }  cursor-pointer`}
                >
                  Footer
                </div>
              </li>
              <li>
                <div
                  onClick={() => setOpenTab(3)}
                  className={`-mb-px border-b-4 border-current pb-2 mr-3 ${
                    openTab === 3 ? 'text-cyan-500' : 'text-gray-500'
                  }  cursor-pointer`}
                >
                  Ads
                </div>
              </li>
              <li>
                <div
                  onClick={() => setOpenTab(4)}
                  className={`-mb-px border-b-4 border-current pb-2 mr-3 ${
                    openTab === 4 ? 'text-cyan-500' : 'text-gray-500'
                  }  cursor-pointer`}
                >
                  Copy Right
                </div>
              </li>
            </ul>
            <div className="pb-3 mt-6 bg-white w-full">
              <div className={openTab === 1 ? 'block' : 'hidden'}>
                <form onSubmit={submitWebsiteCat}>
                  <div className="grid grid-flow-col gap-3">
                    <div className="col-span-1 ">
                      {categories &&
                        categories
                          ?.filter((omt) => omt?.slug !== 'uncategorized')
                          ?.slice(0, 15)
                          ?.map((c, i) => (
                            <li key={i} className="list-none my-2">
                              <input
                                type="checkbox"
                                className="mr-2"
                                onChange={handleToggle(c._id)}
                                checked={findOutCategory(c._id)}
                              />
                              <label>{c?.name}</label>
                            </li>
                          ))}
                    </div>

                    <div className="col-span-4">
                      {categories
                        ?.filter((omt) => omt?.slug !== 'uncategorized')
                        ?.slice(16, 100)
                        ?.map((c, i) => (
                          <li key={i} className="list-none my-2">
                            <input
                              type="checkbox"
                              className="mr-2"
                              onChange={handleToggle(c._id)}
                              checked={findOutCategory(c._id)}
                            />
                            <label>{c?.name}</label>
                          </li>
                        ))}
                    </div>
                  </div>

                  <div className="d-grid gap-2 my-5 w-36">
                    <button
                      type="submit"
                      className={`w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform ${
                        loading
                          ? 'bg-gray-500 hover:bg-gray-500'
                          : 'bg-cyan-500 hover:bg-cyan-500'
                      }  rounded-md  focus:outline-none focus:bg-cyan-600`}
                      disabled={loading}
                    >
                      Update menu
                    </button>
                  </div>
                </form>
              </div>

              <div className={openTab === 2 ? 'block' : 'hidden'}>
                <form onSubmit={submitFooter}>
                  <div className="grid grid-flow-col gap-3">
                    <div className="col-span-1">
                      <textarea
                        onChange={onFooterChange}
                        value={footerText}
                        className="block p-2.5 w-full min-h-[10rem] text-sm text-gray-900 bg-gray-50 rounded border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Footer text"
                      ></textarea>
                    </div>
                  </div>

                  <div className="d-grid gap-2 my-5 w-36">
                    <button
                      type="submit"
                      className={`w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform ${
                        loading
                          ? 'bg-gray-500 hover:bg-gray-500'
                          : 'bg-cyan-500 hover:bg-cyan-500'
                      }  rounded-md  focus:outline-none focus:bg-cyan-600`}
                    >
                      Update
                    </button>
                  </div>
                </form>
              </div>

              <div className={openTab === 3 ? 'block' : 'hidden'}>
                {adverts && adverts?.length === 3 ? (
                  <div className="grid grid-cols-1 xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-5">
                    {adverts?.map((ad) => {
                      return (
                        <div
                          className="rounded overflow-hidden shadow-lg"
                          key={ad?._id}
                        >
                          <img
                            className="w-full"
                            src={ad?.image}
                            alt="Ads"
                            style={{ height: '245px' }}
                          />
                          <div className="px-6 py-4">
                            <div className="font-bold text-sm mb-2">
                              {ad?.title.substring(0, 50).trimEnd() + '...'}
                            </div>
                          </div>
                          <div className="px-6 pt-4 pb-6 text-center">
                            <button onClick={() => handleEdit(ad?._id)}>
                              <span className="mx-5">
                                <EditOutlined
                                  className="text-green-500"
                                  style={{
                                    fontSize: '20px',
                                  }}
                                />
                              </span>
                            </button>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div>
                    <div className="md:col-span-5">
                      <label>Advertisement Image</label>

                      <ImageUpload
                        values={values}
                        setValues={setValues}
                        setLoading={setLoading}
                      />
                    </div>

                    <AdsForm
                      handleSubmit={handleSubmit}
                      handleChange={handleChange}
                      setValues={setValues}
                      values={values}
                      loading={loading}
                    />
                  </div>
                )}

                {showModal && (
                  <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="relative w-10/12 my-6 mx-auto max-w-3xl">
                      {/*content*/}
                      <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                          <h3 className="text-xl font-semibold">
                            {modalData?.title}{' '}
                            {modalLoading && (
                              <div className="flex justify-start items-center">
                                <FadeLoader
                                  color="#06b6d4"
                                  loading={modalLoading}
                                  size={20}
                                />
                              </div>
                            )}
                          </h3>
                        </div>
                        {/*body*/}
                        <div className="relative p-6 flex-auto">
                          <div className="md:col-span-5">
                            <label>Advertisement Image</label>
                            {values?.images.length === 0 && (
                              <img
                                src={values?.image}
                                alt="Ads"
                                className="mt-2"
                                style={{ height: '265px', width: '300px' }}
                              />
                            )}
                            <ImageUpload
                              values={values}
                              setValues={setValues}
                              setLoading={setModalLoading}
                            />
                          </div>

                          <AdsForm
                            handleSubmit={submitAdsUpdated}
                            handleChange={handleChange}
                            setValues={setValues}
                            values={values}
                            loading={modalLoading}
                          />
                        </div>

                        <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                          <button
                            className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            type="button"
                            onClick={() => {
                              setShowModal(false);
                              setModalData({});
                              setValues({
                                ...values,
                                title: '',
                                btnText: '',
                                btnLink: '',
                                images: [],
                              });
                            }}
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className={openTab === 4 ? 'block' : 'hidden'}>
                <form onSubmit={submitFooterCopy}>
                  <div className="grid grid-flow-col gap-3">
                    <div className="col-span-1">
                      <textarea
                        onChange={onFooterCopyChange}
                        value={footerCopyText}
                        className="block p-2.5 w-full min-h-[10rem] text-sm text-gray-900 bg-gray-50 rounded border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="© Your website"
                      ></textarea>
                    </div>
                  </div>

                  <div className="d-grid gap-2 my-5 w-36">
                    <button
                      type="submit"
                      className={`w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform ${
                        loading
                          ? 'bg-gray-500 hover:bg-gray-500'
                          : 'bg-cyan-500 hover:bg-cyan-500'
                      }  rounded-md  focus:outline-none focus:bg-cyan-600`}
                    >
                      Update
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebsiteSettings;
