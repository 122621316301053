import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import CategoryForm from '../../../components/forms/CategoryForm'
import {
  createCategory,
  getCategories,
  removeCategory,
} from '../../../functions/category'
import { toast } from 'react-toastify'
import { Link, NavLink } from 'react-router-dom'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import LocalSearch from '../../../components/forms/LocalSearch'
import ImageUpload from '../../../components/forms/ImageUpload'
import FadeLoader from 'react-spinners/FadeLoader'
import { useStateContext } from '../../../contexts/ContextProvider'
import AdminSidebar from '../../../components/sidebar/AdminSidebar'
import Navbar from '../../../components/nav/Navbar'
import HeaderProfile from '../../../components/HeaderProfile'

const initialState = {
  categories: [],
  images: [],
}

const AdminCategory = () => {
  const user = useSelector((state) => state.user)
  const [name, setName] = useState('')
  const [loading, setLoading] = useState(false)
  const [values, setValues] = useState(initialState)
  const [keyword, setKeyword] = useState('')
  const { activeMenu } = useStateContext()

  // console.log(values)

  useEffect(() => {
    loadCategories()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const loadCategories = () => {
    getCategories()
      .then((res) => {
        if (res.status === 'ok') {
          setValues({ ...values, categories: res.data })
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message)
      })
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    if (!values.images.length) {
      return toast.error('Please upload image')
    }

    setLoading(true)
    let data = {
      name,
      catImg: values.images,
    }
    createCategory(data, user.token)
      .then((res) => {
        if (res.status === 'ok') {
          setLoading(false)
          toast.success(`"${name}" is created`)
          loadCategories()
          window.location.reload()
        } else {
          setLoading(false)
          toast.error(res)
        }
      })
      .catch((err) => {
        setLoading(false)
        toast.error(err.response.data.message)
      })
  }

  const handleRemove = async (name, slug) => {
    if (window.confirm('Are you sure you want to delete?')) {
      try {
        setLoading(true)
        const response = await removeCategory(slug, user.token)

        if (response.status === 'ok') {
          toast.success(`"${name}" is deleted`)

          setValues((prevState) => ({
            ...prevState,
            categories: prevState.categories.filter(
              (category) => category.slug !== slug
            ),
          }))
        } else {
          toast.error(response.message)
        }
      } catch (error) {
        toast.error(error.response.data.message)
      } finally {
        setLoading(false)
      }
    }
  }

  const searched = (keyword) => (c) => c.name.toLowerCase().includes(keyword)

  return (
    <div className="flex relative">
      {activeMenu ? (
        <div className="w-72 sidebar dark:bg-secondary-dark-bg bg-white ">
          <AdminSidebar />
        </div>
      ) : (
        <div className="w-0 dark:bg-secondary-dark-bg">
          <AdminSidebar />
        </div>
      )}
      <div
        className={
          activeMenu
            ? 'dark:bg-main-dark-bg  bg-main-bg min-h-screen w-full  '
            : 'bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 '
        }
      >
        <div className="md:static bg-main-bg dark:bg-main-dark-bg navbar w-full">
          <Navbar />
        </div>
        <div className="m-2 md:m-10 mt-10 p-2 md:p-5 bg-white rounded-1xl">
          <HeaderProfile title="Category" />
          <div className="w-96">
            {loading && (
              <div className="flex justify-start items-center">
                <FadeLoader color="#06b6d4" loading={loading} size={20} />
              </div>
            )}
            <div className="md:col-span-5">
              <label>Category Image</label>
              <ImageUpload
                values={values}
                setValues={setValues}
                setLoading={setLoading}
              />
            </div>

            <CategoryForm
              handleSubmit={handleSubmit}
              name={name}
              setName={setName}
              loading={loading}
            />
          </div>
          <br />
          <LocalSearch keyword={keyword} setKeyword={setKeyword} />

          <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
            <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
              <table className="min-w-full leading-normal">
                <tbody>
                  {values.categories?.filter(searched(keyword))?.map((c) => (
                    <tr key={c._id}>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm 3/5">
                        <div className="flex items-center">
                          <div className="flex-shrink-0 w-10 h-10 hidden sm:table-cell">
                            <img
                              className="w-full h-full rounded-full"
                              src={c.catImg[0]?.url}
                              alt="Category"
                            />
                          </div>
                          <div className="ml-3">
                            <p className="text-gray-900 whitespace-no-wrap">
                              {c.name}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap text-center">
                          {c.slug !== 'uncategorized' && (
                            <>
                              <span
                                onClick={() => handleRemove(c.name, c.slug)}
                                className="btn btn-sm float-right mx-5"
                              >
                                <DeleteOutlined className="text-red-500" />
                              </span>
                              <Link
                                to={`/admin/category/${c.slug}`}
                                as={NavLink}
                              >
                                <span className="btn btn-sm float-right">
                                  <EditOutlined className="text-green-500" />
                                </span>
                              </Link>
                            </>
                          )}
                        </p>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminCategory
