import React, { useState, useEffect, useRef } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import {
  deleteComment,
  getPost,
  likePost,
  postComment,
} from '../../functions/post'
import moment from 'moment'
import { smartTrim } from '../../utils/helpers'
import parse from 'html-react-parser'
import { getCategories } from '../../functions/category'
import { toast } from 'react-toastify'
import {
  HeartOutlined,
  CommentOutlined,
  EyeOutlined,
  HeartFilled,
  DeleteFilled,
} from '@ant-design/icons'
import FadeLoader from 'react-spinners/FadeLoader'
import { useSelector } from 'react-redux'
import WebSideBar from '../Sidebar/WebSideBar'
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  EmailIcon,
  EmailShareButton,
  LinkedinShareButton,
  LinkedinIcon,
  PinterestShareButton,
  PinterestIcon,
  TelegramShareButton,
  TelegramIcon,
} from 'react-share'

const PostDetails = ({ socket, adverts }) => {
  const commentRef = useRef()
  const { slug } = useParams()
  const user = useSelector((state) => state.user)
  const [post, setPost] = useState([])
  const navigation = useNavigate()
  const [likes, setLikes] = useState([])
  const [comments, setComments] = useState(post?.comments)
  const [text, setText] = useState('')
  const [loading, setLoading] = useState(false)
  const [errPost, setErrorPost] = useState(false)

  const loadPost = () => {
    setLoading(true)
    getPost(slug)
      .then((p) => {
        if (!p.data) {
          setLoading(false)
          return setErrorPost(true)
        }
        setPost([p.data])
        setLikes(p.data?.likes)
        setComments(p.data?.comments)
        setLoading(false)
      })
      .catch((err) => {
        setPost([])
        setLoading(false)
      })
  }

  const isLiked = likes?.some((like) => like.user === user?.id)

  useEffect(() => {
    if (post[0] === null) {
      navigation('*')
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (errPost) {
      navigation('*')
    }
    // eslint-disable-next-line
  }, [errPost])

  useEffect(() => {
    if (socket.current) {
      socket.current.on(
        'newNotificationReceived',
        ({ firstName, profilePic, postId }) => {
          if (firstName) {
            setInterval(() => {
              loadPost()
            }, 5000)
          }
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    loadPost()
    // eslint-disable-next-line
  }, [])

  const handleClickRef = () => {
    commentRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  const handleCommentSubmit = async (e) => {
    e.preventDefault()
    try {
      setLoading(true)

      if (text?.length < 5) {
        setLoading(false)
        return toast.error('Comments should be at least 5 character')
      }

      await postComment(
        user?.token,
        post[0]?._id,
        user?.id,
        text,
        setComments,
        setText
      )
      loadPost()

      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const handleDelete = async (cId, setComments) => {
    await deleteComment(user?.token, post[0]?._id, cId, setComments)
  }

  return (
    <>
      <div className="container mx-auto flex flex-wrap py-6">
        <section className="w-full md:w-2/3">
          {loading && (
            <div className="flex justify-center items-center">
              <FadeLoader color="#06b6d4" loading={loading} size={20} />
            </div>
          )}
          {post?.length > 0 &&
            post?.map((p, i) => {
              return (
                <article className="flex flex-col shadow my-4" key={i}>
                  <img
                    src={p?.images[0].url}
                    alt={p?.title}
                    className="w-full md:h-[40rem] sm:h-[20rem]"
                  />

                  <div className="flex p-6 justify-between">
                    <div className="text-1xl flex justify-center items-center">
                      {isLiked ? (
                        <HeartFilled
                          className="text-red-500"
                          style={{
                            fontSize: '20px',
                          }}
                          onClick={() => {
                            if (socket.current) {
                              socket.current.emit('likePost', {
                                postId: p?._id,
                                userId: user?.id,
                                like: isLiked ? false : true,
                              })

                              socket.current.on('postLiked', () => {
                                if (isLiked) {
                                  setLikes((prev) =>
                                    prev.filter(
                                      (like) => like.user !== user?.id
                                    )
                                  )
                                }
                                //
                                else {
                                  setLikes((prev) => [
                                    ...prev,
                                    { user: user?.id },
                                  ])
                                }
                              })
                            } else {
                              likePost(
                                user?.token,
                                p?._id,
                                user?.id,
                                setLikes,
                                isLiked ? false : true
                              )
                            }
                          }}
                        />
                      ) : (
                        <HeartOutlined
                          className="text-cyan-500"
                          style={{
                            fontSize: '20px',
                          }}
                          onClick={() => {
                            if (socket.current) {
                              socket.current.emit('likePost', {
                                postId: p?._id,
                                userId: user?.id,
                                like: isLiked ? false : true,
                              })

                              socket.current.on('postLiked', () => {
                                if (isLiked) {
                                  setLikes((prev) =>
                                    prev.filter(
                                      (like) => like.user !== user?.id
                                    )
                                  )
                                }
                                //
                                else {
                                  setLikes((prev) => [
                                    ...prev,
                                    { user: user?.id },
                                  ])
                                }
                              })
                            } else {
                              likePost(
                                user?.token,
                                p?._id,
                                user?.id,
                                setLikes,
                                isLiked ? false : true
                              )
                            }
                          }}
                        />
                      )}

                      <p className="mx-2 text-lg">{likes?.length}</p>
                    </div>
                    <div className="text-1xl flex justify-center items-center">
                      <p className="text-lg">{p?.numViews}</p>

                      <EyeOutlined
                        className="text-blue-500 mx-3"
                        style={{
                          fontSize: '20px',
                        }}
                      />
                      <CommentOutlined
                        className="text-blue-500 mx-3"
                        style={{
                          fontSize: '20px',
                        }}
                        onClick={handleClickRef}
                      />
                    </div>
                  </div>

                  <div className="bg-white flex flex-col justify-start p-6">
                    <NavLink
                      to={`/category/${p?.category?.slug}`}
                      className="text-cyan-600 text-sm font-bold uppercase pb-4"
                    >
                      {!p?.category?.name ? 'Uncategorized' : p?.category?.name}
                    </NavLink>
                    <NavLink
                      to={`/post/${p?.slug}`}
                      className="text-2xl hover:text-gray-700 pb-4"
                    >
                      {p?.title}
                    </NavLink>
                    <p className="text-sm pb-3 flex flex-row">
                      <NavLink to={`/${p?.postedBy?.username}`}>
                        <img
                          src={p?.postedBy?.profilePic}
                          alt="Profile Pic"
                          className="rounded-full w-6 h-6 mr-2"
                        />
                      </NavLink>
                      <NavLink
                        to={`/${p?.postedBy?.username}`}
                        className="font-semibold hover:text-gray-800"
                      >
                        {p?.postedBy?.firstName}
                      </NavLink>
                      , Published on: {moment(p?.createdAt).fromNow()}
                    </p>

                    <div className="text-lg flex row-auto">
                      Share:{' '}
                      <p className="ml-2">
                        <FacebookShareButton
                          className="mr-2"
                          url={`https://coinanalysis.org/post/${p?.slug}`}
                          quote={p?.title}
                        >
                          <FacebookIcon size={30} round={true} />
                        </FacebookShareButton>

                        <TwitterShareButton
                          className="mx-2"
                          url={`https://coinanalysis.org/post/${p?.slug}`}
                          quote={p?.title}
                        >
                          <TwitterIcon size={30} round={true} />
                        </TwitterShareButton>

                        <LinkedinShareButton
                          className="mx-2"
                          url={`https://coinanalysis.org/post/${p?.slug}`}
                          quote={p?.title}
                        >
                          <LinkedinIcon size={30} round={true} />
                        </LinkedinShareButton>

                        <PinterestShareButton
                          className="mx-2"
                          url={`https://coinanalysis.org/post/${p?.slug}`}
                          quote={p?.title}
                        >
                          <PinterestIcon size={30} round={true} />
                        </PinterestShareButton>

                        <TelegramShareButton
                          className="mx-2"
                          url={`https://coinanalysis.org/post/${p?.slug}`}
                          quote={p?.title}
                        >
                          <TelegramIcon size={30} round={true} />
                        </TelegramShareButton>

                        <EmailShareButton
                          className="mx-2"
                          url={`https://coinanalysis.org/post/${p?.slug}`}
                          quote={p?.title}
                        >
                          <EmailIcon size={30} round={true} />
                        </EmailShareButton>
                      </p>
                    </div>

                    <div className="leading-5 text-justify">
                      {parse(
                        `${p?.description.replace(
                          /<p[^>"']*((("[^"]*")|('[^']*'))[^"'>]*)*>/g,
                          '<br/>'
                        )}`
                      )}
                    </div>

                    <div className="my-5">
                      <h1 className="text-2xl text-left text-gray-700">
                        <div className="flex justify-start items-center">
                          <span style={{ marginRight: '20px' }}>Comments </span>
                          <FadeLoader
                            color="#06b6d4"
                            loading={loading}
                            size={16}
                          />
                        </div>
                      </h1>

                      <div
                        ref={commentRef}
                        className="my-4 w-full mb-4 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600"
                      >
                        {!user && (
                          <div className="my-3 mx-2">
                            {' '}
                            <h4 className="text-lg">
                              You must{' '}
                              <NavLink className="text-red-400" to="/login">
                                logged in
                              </NavLink>{' '}
                              to post a comment
                            </h4>
                          </div>
                        )}
                        {user?.token && (
                          <form onSubmit={handleCommentSubmit}>
                            <div className="px-4 py-2 bg-white rounded-t-lg dark:bg-gray-800">
                              <textarea
                                id="comment"
                                value={text}
                                onChange={(e) => setText(e.target.value)}
                                rows="4"
                                className="w-full px-0 text-sm text-gray-900 bg-white border-0 dark:bg-gray-800 focus:outline-none focus:ring focus:ring-white dark:text-white dark:placeholder-gray-400"
                                placeholder="Write a comment..."
                                required
                              ></textarea>
                            </div>
                            <div className="flex items-center justify-between px-3 py-2 border-t dark:border-gray-600">
                              <button
                                type="submit"
                                className="inline-flex items-center py-2.5 px-4 text-xs font-medium text-center text-white bg-cyan-500 rounded hover:bg-cyan-800"
                                disabled={loading}
                              >
                                Post comment
                              </button>
                            </div>
                          </form>
                        )}
                      </div>

                      {user?.token && comments?.length === 0 && (
                        <div className="my-3 mx-2">
                          {' '}
                          <h4 className="text-md">
                            No comments yet. <br />
                            <span
                              onClick={handleClickRef}
                              className="text-cyan-600 cursor-pointer"
                            >
                              Be the first to share what you think!
                            </span>
                          </h4>
                        </div>
                      )}

                      {comments?.map((c, i) => {
                        return (
                          <div key={i}>
                            <div className="flex-col my-4 w-full py-4 sm:px-4 sm:py-4 md:px-4">
                              <div className="flex flex-row">
                                <img
                                  className="object-cover w-12 h-12 border-2 border-gray-300 rounded-full"
                                  alt="Noob master's avatar"
                                  src={c?.user?.profilePic}
                                />
                                <div className="flex-col mt-1">
                                  <div className="flex items-center flex-1 px-4 font-bold leading-tight">
                                    {c?.user?.firstName +
                                      ' ' +
                                      c?.user?.lastName}
                                    <span className="ml-2 text-xs font-normal text-gray-500">
                                      {moment(c?.date).fromNow()}
                                    </span>
                                    {user?.role === 'admin' && (
                                      <p className="ml-5">
                                        <DeleteFilled
                                          style={{
                                            color: 'red',
                                          }}
                                          onClick={() =>
                                            handleDelete(c._id, setComments)
                                          }
                                        />
                                      </p>
                                    )}
                                  </div>
                                  <div className="flex-1 px-2 ml-2 text-sm font-medium leading-loose text-gray-600">
                                    {c?.text}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr />
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </article>
              )
            })}
        </section>

        {/* Sidebar */}
        <WebSideBar adverts={adverts} />
      </div>
    </>
  )
}

export default PostDetails
